import React from 'react';
import { FormControl, TextField } from '@mui/material';
import FormError from '../../FormError/AppFormError';

import './AppText.scss';

function AppText ({id, name, label, value, type, errors, errorMessage, onChange, disabled,...props}){

return(
    <>
        <FormControl fullWidth focused >       
        <TextField
            label={label}
            id = {id}
            name ={name}
            type={type?type:'text'}
            slotProps={{
                inputLabel: {
                    shrink: value
                },
              }}
            fullWidth
            disabled = {disabled?disabled:false}
            error = {errors}
            value={value}
            onChange={(e)=>onChange(e)}
            variant="standard"
            {...props}
        />
        </FormControl>  
        {errorMessage && <FormError errorMessage={errorMessage} />}
        
    </>
);


}

export default AppText;
