import * as Yup from 'yup';

export const validationSchema = (testDatabase) => Yup.object({
  accountOrigin: Yup.string().required('Cuenta origen requerida'),
  accountsDestination: Yup.array().required().min(1, 'Debe seleccionar al menos una cuenta destino'),
  selectForms: Yup.array().required().min(1, 'Debe seleccionar al menos un formulario'),
  enableRecords: Yup.boolean(),
  email: Yup.array().of(
    Yup.string()
      .email('Ingresa un correo electrónico válido')
      .test('domain-check', 'El correo debe terminar con "@galapagoagro.co"', value => !value || value.endsWith('@galapagoagro.co'))
  ).test('all-email-check', 'Todos los correos deben terminar con "@galapagoagro.co"', value => {
    if (Array.isArray(value) && value.length > 0) {
      return value.every(email => email.endsWith('@galapagoagro.co'));
    }
    return true;
  }),
});
