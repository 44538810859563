import React, { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useFirebase } from '../../core/context/firebase-context';
import { paginate } from '../../core/components/Util/pagination';
import axios from 'axios';
import * as Yup from "yup";


import { Form, Formik } from 'formik';

import AppPagination from '../../core/components/Pagination/AppPagination';
import AppJsonDetails from './AppJsonDetails';
import AppTableBackup from './AppTableBackup';
import AppButton from "../../core/components/Button/AppButton";
import "./index.scss";
import FormError from '../../core/components/FormError/AppFormError';
import AppText from '../../core/components/Input/Text/AppText';
import { useAxiosInstance } from '../../api/axiosInstance';
import { ENDPOINTS } from '../../api/endpoints';



function ExploradorBackup() {
    const { user } = useFirebase();
    const { fb_id } = useParams();
    const navigate = useNavigate();

    const [resultAlert, setResultAlert] = useState(true);
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [selectedItem, setSelectedItem] = useState(null);
    const [expanded, setExpanded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const apiUrlEnv = process.env.REACT_APP_BASE_URL;
    const axiosInstance = useAxiosInstance();

    useEffect(() => {
        const title = document.getElementById('title');
        title ? (title.innerHTML = 'Explorador backup') : null;
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            if (fb_id) {
                await filteredBackup(fb_id);
            }
        };
        fetchData();
    }, [fb_id]);


    //Maneja el cambio de searchTerm
    useEffect(() => {
        if (searchTerm) {
            setPage(0);
        }
    }, [searchTerm]);

    //Petición
    const filteredBackup = async (fb_id) => {
        setLoading(true);
        setResultAlert(false);
        return await axiosInstance.get(ENDPOINTS.DATA.HISTORY(fb_id)).then(response => {
            const data = response.data;
            if (data) {
                setErrorMessage('');
                if (data.length > 0) {
                    setData(data);
                    setPage(0);
                    setLoading(false);
                }else {
                    setLoading(false);
                    setData([]);
                    setErrorMessage('No se encontraron registros');
                }
                return data;
            }
            
        })
        .catch(error => {
            setErrorMessage(error);
            setData([]);
            setLoading(false);
            return [];
        });
    };



    //Paginación
    const paginatedData = paginate(data, page, rowsPerPage);

    //Nombres de las columnas de la tabla
    const columnsToShow = ['id', 'account_key', 'fb_id', 'fecha', 'fechaCreacion', 'fechaModificacion', 'formulario', 'fuente', 'operacion', 'usuarioCreacion', 'usuarioModificacion', 'fecha_servidor'];


    //Ver más detalles
    const handleShowDetails = (item) => {
        setSelectedItem(item);
        setExpanded(true);
    };

    const handleCloseDetails = () => {
        setSelectedItem(null);
        setExpanded(false);
    };

    const returnPage = () => {
        navigate('/');
    };

    //Formatear fecha servidor
    const formatDate = (seconds, nanoseconds) => {
        const milliseconds = seconds * 1000 + nanoseconds / 1000000;
        const date = new Date(milliseconds);

        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZoneName: 'short',
        };

        return new Intl.DateTimeFormat('es-ES', options).format(date);
    };


    return (
        <div className={`container-explorador-backup${expanded ? ' expanded' : ''}`}>

            <div className='container-table-json'>
                <div className='container-table-pagination'>
                    <p>Buscar registro</p>
                    <Formik
                        initialValues={{ fb_id: fb_id ? fb_id : '' }}
                        validationSchema={Yup.object({
                            fb_id: Yup.string()
                                .required("Ingrese un fb_id")
                        })}
                        onSubmit={async (values, actions) => {
                            navigate(`/explorador-backup/${values.fb_id}`, { replace: true });
                        }}
                    >

                        {(formik, isSubmitting) => (
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    formik.handleSubmit(e);
                                }}
                            >
                                <AppText
                                    label="fb_id"
                                    id="fb_id"
                                    name="fb_id"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    value={formik.values.fb_id}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.fb_id && !!formik.errors.fb_id
                                    }
                                    helperText={
                                        formik.touched.fb_id && formik.errors.fb_id
                                    }
                                />
                                <AppButton
                                    type="submit"
                                    disabled={isSubmitting}
                                    tipo="buscar"
                                    loading={loading}
                                    variant='contained'
                                >
                                    Buscar
                                </AppButton>
                            </Form>
                        )}
                    </Formik>
                        {errorMessage && <FormError errorMessage={errorMessage} />}

                    {data.length > 0 && (
                        <>
                            <AppTableBackup
                                data={paginatedData}
                                onShowDetails={handleShowDetails}
                                expanded={expanded}
                                columnsToShow={columnsToShow}
                                formatDate={formatDate}
                            />
                            <AppPagination
                                results={data}
                                page={page}
                                setPage={setPage}
                                rowsPerPage={rowsPerPage}
                                setRowsPerPage={setRowsPerPage}
                            />
                        </>                      
                        
                    )}
                    <div className='button-container'>
                        <AppButton variant='contained' className='button_return' onClick={returnPage}>Regresar</AppButton>
                    </div>

                </div>
                {selectedItem && (
                    <div className='container-json'>
                        <AppJsonDetails
                            selectedItem={selectedItem}
                            onClose={handleCloseDetails}
                            text='Cancelar'
                            showSeeMore={true}
                            expanded={expanded}
                        />
                    </div>
                )}
            </div>

        </div >
    );
}

export default ExploradorBackup;
