import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import "./index.scss";

import { useFirebase } from "../../core/context/firebase-context";
import AppSideMenu from "../../core/components/SideMenu/AppSideMenu";


import Home from '../Home/index.js';
import Images from '../Images/index.js';
import Tags from '../Tags/index.js';
import Users from '../Users/index.js';
import Organizations from '../Organizations/index.js';
import OrganizationSelected from '../Organizations/OrganizationSelected.js';
import AccountDetails from '../Organizations/AccountDetail/AccountDetails';
import AppPopupEditUser from '../Users/EditUser';
import ExploradorBackup from '../ExploradorBackup';
import AppInfoDetails from '../ExploradorBackup/AppInfoDetails';
import NotAllowed from "../NotAllowed/index.js";

const ConditionalRoute = ({ permission, component: Component }) => {
  return permission ? <Component /> : <Navigate to="/not-allowed" />;
};

const DashboardRoutes = () => {
  const { user } = useFirebase();
  const { organizations, users, images, tags, backup } = user.permissions;

  return (
    <Routes>
      <Route path="/" element={<Home />} />

      <Route path="/not-allowed" element={<NotAllowed />} />

      <Route path="/images" element={<ConditionalRoute permission={images.view} component={Images} />} />
      <Route path="/tags" element={<ConditionalRoute permission={tags.view} component={Tags} />} />
      <Route path="/users/:uid" element={<ConditionalRoute permission={users.view} component={AppPopupEditUser} />} />
      <Route path="/users" element={<ConditionalRoute permission={users.view} component={Users} />} />
      <Route path="/organizations/:orgId/accounts/:accountId" element={<ConditionalRoute permission={organizations.view} component={AccountDetails} />} />
      <Route path="/organizations/:id" element={<ConditionalRoute permission={organizations.view} component={OrganizationSelected} />} />
      <Route path="/organizations" element={<ConditionalRoute permission={organizations.view} component={Organizations} />} />
      <Route path="/explorador-backup/:fb_id/:doc_id" element={<ConditionalRoute permission={backup.view} component={AppInfoDetails} />} />
      <Route path="/explorador-backup/:fb_id" element={<ConditionalRoute permission={backup.view} component={ExploradorBackup} />} />
      <Route path="/explorador-backup" element={<ConditionalRoute permission={backup.view} component={ExploradorBackup} />} />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

function Dashboard(props) {
  const [menuOpen, openMenu] = React.useState(false);

  const onStateChange = (state) => {
    if (!state.isOpen) openMenu(false);
  };

  return (
    <section className="dashboard">
      <AppSideMenu
        isOpen={menuOpen}
        openMenu={openMenu}
        onStateChange={onStateChange}
      />

      <div className="dashboard-content">
        <header className="header hide-for-large">
          <div className="container-title">
            <div className="title" id="title">
              {" "}
              Menu{" "}
            </div>
          </div>
        </header>

        <div className="content">
          <DashboardRoutes />
        </div>
      </div>
    </section>
  );
}

export default Dashboard;
