import React from 'react';

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { formatTableCell } from '../Util/formatTableCell';
import FilterSelect from '../FilterTable/filterSelect';
import './AppTableGeneral.scss';



const AppTableGeneral = ({ title, data, columns, page, rowsPerPage, onRowClick, possibleEstates, possibleProfiles, handleAccountFilterChange, profileFilter, accountState }) => {
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const sortedData = data.sort((a, b) => {
    if (a.cuenta_maestra === a.value && b.cuenta_maestra !== b.value) {
      return -1;
    } else if (a.cuenta_maestra !== a.value && b.cuenta_maestra === b.value) {
      return 1;
    }
    return 0;
  });

  const pageData = sortedData.slice(startIndex, endIndex);

  if (!pageData) return (<div>cargando...</div>);

  return (
    <TableContainer component={Paper} style={{ marginTop: 20 }} >
      <Table stickyHeader aria-label="sticky table">
        <TableHead >
          {title && (
            <TableRow>
              <TableCell colSpan={6}>
                <Typography className='table-header'> {title}</Typography>
              </TableCell>
            </TableRow>
          )}
          
          <TableRow>
            {columns.map((column, index) => (
              <TableCell key={index} 
              className='table-header' 
              style={{ textAlign: 'center', backgroundColor: '#EFEFEF' }} 
              >
                {column === 'Perfil' || column === 'Estado' ? (
                  <div className='container-filter' 
                  style={{ marginLeft:'35px' , textAlign:'center'}} >
                    {column.label}
                    {column === 'Perfil' ? (
                      <FilterSelect
                        name="perfil"
                        label='Perfil'
                        value={profileFilter}
                        onChange={handleAccountFilterChange}
                        options={possibleProfiles}
                      />
                    ) : (
                      <FilterSelect
                        name="estado"
                        label='Estado'
                        value={accountState}
                        onChange={handleAccountFilterChange}
                        options={possibleEstates}
                      />
                    )}
                  </div>
                ) : (
                  column.label
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {pageData.map((item, rowIndex) => (
            <TableRow key={rowIndex}
              onClick={() => onRowClick(item)}
              className={(item.cuenta_maestra && item.value && item.cuenta_maestra === item.value) ? 'master-account-row' : 'table-cell'}
            >
              {columns.map(({value}, colIndex) => (
                <TableCell key={colIndex}
                  style={{ textAlign: 'center' }}
                  className='table-row'
                >
                  {value === 'estado' ? (
                    <div className={item[value] === 'activo' ? 'fondoVerde' : 'fondoRojo'}>
                      {formatTableCell(item[value]?.charAt(0).toUpperCase() + item[value]?.slice(1))}
                    </div>
                  ) : (
                    formatTableCell(item[value])
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer >
  );
};

export default AppTableGeneral;



