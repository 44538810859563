import React from 'react';

// Importa funciones específicas de Firebase en lugar de la importación general
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup, onAuthStateChanged, signOut, getIdToken } from 'firebase/auth';
import { getDatabase, ref as databaseRef, get, update, child, push as databasePush, onValue, off  } from 'firebase/database';
import { getStorage, ref as storageRef, getDownloadURL, uploadBytes, updateMetadata, listAll, getMetadata } from 'firebase/storage';
import { getFunctions, httpsCallable  } from 'firebase/functions';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

// Importa la configuración de Firebase
import firebaseSettings from '../firebase/settings';


const FirebaseContext = React.createContext();
const dbApp = initializeApp(firebaseSettings);
const adminDatabase = process.env.REACT_APP_ADMIN_DATABASE || 'galapp-web-administrador';

const dbFirestore = getFirestore(dbApp);
const auth = getAuth(dbApp);
const defaultDb = getDatabase(dbApp);
const storage = getStorage(dbApp);
const functions = getFunctions(dbApp);

function FirebaseProvider(props) {
  const [user, setUser] = React.useState(null);
  const [isInitialized, setInitialize] = React.useState(false);

  const adminDB = getDatabase(dbApp, `https://${adminDatabase}.firebaseio.com/`);

  const rolesRef = databaseRef(adminDB, '/roles');
  const usersRef = user ? databaseRef(adminDB, `/users/${user.uid}`) : null;
  
  
  /**
   * Inicializa la aplicación y configura la base de datos
   * con base al usuario actual.
   * @param {Object} currentUser
  */
 
 const initialize = async (currentUser) => {
    try {
      const currentUserRef = databaseRef(adminDB, `/users/${currentUser.uid}`);
      const userSnapshot = await get(currentUserRef);
      const userAdmin = userSnapshot.val();

      if (!userAdmin) {
        logout();
      } else {
        const userRolesPermissions = await getRolesPermission(userAdmin.roles);
        setUser({ ...currentUser, ...userAdmin, permissions: userRolesPermissions });
      }
    
      setInitialize(true);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }    
  };
  
  if (!isInitialized && !user) {
    onAuthStateChanged(auth, (currentUser) => {
      if (!currentUser) {
        setUser(null);
        setInitialize(true);
        return;
      }
  
      if (!isInitialized) {
        initialize(currentUser);
      }
    });
  }
  

  const getDocumentBackup = async (id) => {
    try {
      const docRef = doc(dbFirestore, 'backup_galapp', id);
      
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        return docSnap.data();
      } else {
        console.error('No such document!');
        return null;
      }
    } catch (error) {
      console.error('Error fetching document:', error);
      return [];
    }
  };


  const signinwith= async () => {
    const provider = new GoogleAuthProvider();
    auth.useDeviceLanguage();
    await signInWithPopup(auth, provider);
    setInitialize(false);
  };
  

  const loginWithEmail = async ({ email, password }) => {
    const response = await signInWithEmailAndPassword(auth, email, password);
    return response;
  };
  
  const logout = async () => {
    try {
      await signOut(auth);
      setUser(null);
    } catch (error) {
      console.error('Error during sign out:', error);
    }
  };
  

  const getRolesPermission = async (roles) => {
    try {
      const permissions = await Promise.all(
        Object.keys(roles).map(async (role) => {
          const roleRef = databaseRef(adminDB, `roles/${role}`);
          const roleSnapshot = await get(roleRef);
          
          if (roleSnapshot.exists()) {
            return roleSnapshot.val();
          } else {
            console.error(`No role found for: ${role}`);
            return null;
          }
        })
      );
  
      const combinedPermissions = permissions.reduce((combined, role) => {
        if (role && role.permissions) {
          Object.keys(role.permissions).forEach((resource) => {
            if (!combined[resource]) {
              combined[resource] = {};
            }
  
            Object.keys(role.permissions[resource]).forEach((action) => {
              if (combined[resource][action] === undefined || role.permissions[resource][action] === true) {
                combined[resource][action] = role.permissions[resource][action];
              }
            });
          });
        }
        return combined;
      }, {});
  
      return combinedPermissions;
    } catch (error) {
      console.error('Error fetching roles permissions:', error);
      throw error;
    }
  };
  


  let isSubscribed = false;

  /**
 * @summary Esta función se autoinvoca para actualizar los roles y permisos del usuario.
 * 
 * @description Función autoinvocada para suscribirse a los cambios en los roles y permisos del usuario de la base de datos del administrador,
 * utilizando las referencias rolesRef y usersRef. Cuando se producen cambios en los roles o permisos,
 * las funciones handlePermissionsChange y handleRolesChange se ejecutan para actualizar los datos del usuario.
 * 
 * @returns {Function} Función que puede ser utilizada para dejar de escuchar los cambios y desuscribirse de los eventos.
 * 
 * @function
 * @name updateUserRolesAndPermissions
 */

  const updateUserRolesAndPermissions = (() => {
    if (isSubscribed) {
      return;
    }
  
    const handlePermissionsChange = async (snapshot) => {
      const newPermissions = await getRolesPermission(user.roles);
  
      if (JSON.stringify(newPermissions) !== JSON.stringify(user.permissions)) {
        setUser((prevUser) => ({ ...prevUser, permissions: newPermissions }));
      }
    };
  
    const handleRolesChange = async (snapshot) => {
      const userData = snapshot.val();
      const newPermissions = await getRolesPermission(userData.roles);
  
      if (JSON.stringify(userData.roles) !== JSON.stringify(user.roles)) {
        setUser((prevUser) => ({
          ...prevUser,
          permissions: newPermissions,
          roles: userData.roles,
        }));
      }
    };
  
    if (rolesRef && usersRef) {
      // Suscribe a cambios en roles y usuarios usando `onValue`
      onValue(rolesRef, handlePermissionsChange);
      onValue(usersRef, handleRolesChange);
  
      isSubscribed = true;
  
      // Retorna una función para cancelar las suscripciones
      return () => {
        off(rolesRef, 'value', handlePermissionsChange);
        off(usersRef, 'value', handleRolesChange);
        isSubscribed = false;
      };
    }
  })();



  const getAllRolesAdmin = async () => {
    try {
      const rolesRef = databaseRef(adminDB, '/roles');
  
      const roleSnapshot = await get(rolesRef);
      const rolesData = roleSnapshot.val();
  
      if (!rolesData) {
        throw new Error('No se encontraron roles en la base de datos');
      }
  
      const rolesArray = Object.entries(rolesData).map(([id, role]) => ({
        id,
        name: role.name,
      }));
  
      return rolesArray;
    } catch (error) {
      console.error('Error al obtener roles desde la base de datos:', error.message);
      throw error;
    }
  };

  //imagenes storage
  const getStorageImages = async (img) => {
    try {
      const imgRef = storageRef(storage, img);
  
      const url = await getDownloadURL(imgRef);
  
      return url;
    } catch (error) {
      console.error('Error fetching image URL from storage:', error);
      throw error;
    }
  };

  //subir imagen al storage y a la base de datos
  const saveImg = async (data) => {
    const fileRef = storageRef(storage, data.nombre);
    await uploadBytes(fileRef, data.url);

    var newMetadata = {
      customMetadata: { tags: data.etiqueta.toString() },
    };

    await updateMetadata(fileRef, newMetadata);

    const idImg = data.nombre.substr(0, data.nombre.indexOf('.'));
    const imgClear = idImg.replaceAll(' ', '');

    const dbPath = databaseRef(defaultDb, `/storage/iconos/${imgClear}`);
    await update(dbPath, data);
  };

  const editImg = async (data) => {
    try {
      let newMetadata = {
        customMetadata: { tags: data.etiqueta.toString() },
      };

      const fileRef = storageRef(storage, data.nombre);
      await updateMetadata(fileRef, newMetadata);
    } catch (error) {
      console.log(error);
    }

  };

  // Lista de todas las imagenes
  const getImg = async () => {
    try {
      const resultFolder = [];
      const ref = storageRef(storage);
  
      const res = await listAll(ref);
  
      res.prefixes.forEach((folderRef) => {
        const infoFolder = {
          name: folderRef.fullPath,
          category: 'folder',
        };
        resultFolder.push(infoFolder);
      });
  
      const imagePromises = res.items.map(async (itemRef) => {
        try {
          const [path, meta] = await Promise.all([
            getStorageImages(itemRef.fullPath),
            getMetadata(itemRef),
          ]);
  
          const metaTags = meta.customMetadata ? meta.customMetadata.tags : '';
  
          const infoField = {
            name: itemRef.fullPath,
            category: 'img',
            img: path,
            size: meta.size,
            type: meta.contentType,
            created: meta.timeCreated,
            updated: meta.updated,
            tags: metaTags,
          };
  
          return infoField;
        } catch (error) {
          console.error(`Error fetching metadata for ${itemRef.fullPath}:`, error);
          return null;
        }
      });
  
      const imageResults = await Promise.all(imagePromises);
  
      resultFolder.push(...imageResults.filter((img) => img !== null));
  
      return resultFolder;
    } catch (error) {
      console.error('Error fetching images and folders:', error);
      throw error;
    }
  };

  const getStorageIcons = async () => {
    try {
      const iconRef = databaseRef(defaultDb, 'storage/iconos');
  
      const snapshot = await get(iconRef);
  
      if (!snapshot.exists()) {
        throw new Error('No icons found in the database');
      }
  
      const icon = snapshot.val();
  
      const iconList = Object.keys(icon).map((id) => {
        const tagString = icon[id].etiqueta.toString();
        return {
          id: id,
          nombre: icon[id].nombre,
          etiqueta: tagString,
        };
      });
  
      return iconList;
    } catch (error) {
      console.error('Error fetching storage icons:', error);
      throw error;
    }
  };

  //Traer lista etiquetas
  const allTags = async () => {
    try {
      const tagsRef = databaseRef(defaultDb, 'storage/etiquetas');
  
      const snapshot = await get(tagsRef);
  
      if (!snapshot.exists()) {
        throw new Error('No tags found in the database');
      }
  
      const tags = snapshot.val();
  
      const tagsList = Object.keys(tags).map((id) => {
        return {
          id: id,
          nombre: tags[id].nombre,
        };
      });
  
      return tagsList;
    } catch (error) {
      console.error('Error fetching tags:', error);
      throw error;
    }
  };

  //Traer nombre de cuentaconst 
  const getAccountName = async (accountKey) => {
  try {
    const accountRef = child(databaseRef(defaultDb, 'cuentas'), accountKey);

    const snapshot = await get(accountRef);
    const account = snapshot.val();

    if (account) {
      const { nombre } = account;
      return nombre;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error getting account name:', error);
    return null;
  }
};


  //Crear etiqueta
  const createTag = async (infoTag) => {
    try {
      const etiquetasRef = databaseRef(defaultDb, 'storage/etiquetas');
  
      const newTagRef = databasePush(etiquetasRef);
      const newDataKey = newTagRef.key;
  
      await update(databaseRef(defaultDb, `/storage/etiquetas/${newDataKey}`), infoTag);
    } catch (error) {
      console.error('Error creating tag:', error);
      throw error;
    }
  };

  //editar tag 
  const editTag = async (name, id) => {
    const tagsRef = databaseRef(defaultDb, `storage/etiquetas/${id}`);
    try {
      await update(tagsRef, { nombre: name });
    } catch (error) {
      console.error('Error al actualizar el nombre de la etiqueta:', error);
    }
  };

 //Traer usuario por correo
  
 const findUserByEmail = async (email) => {
  try {
    const callableFindUser = httpsCallable(functions, 'find_user');
    const { data } = await callableFindUser( email );

    const usuarioData = {
      uid: data.uid,
      nombre: data.nombre || data.displayName,
      correo: data.correo || data.email,
      cuentas: data.cuentas || [],
      celular: data.celular || '',
      organizacion: data.organizacion || '',
      dispositivo: data.device_info || '',
      version: data.version_name ? data.version_name.toString() : '',
      nodo_raiz: data.nodo_raiz ? data.nodo_raiz.toString() : '',
      base_datos: data.base_datos ? data.base_datos.toString() : '',
    };

    return usuarioData;
  } catch (error) {
    throw new Error(error);
  }
};
const findUserByUid = async (uid) => {
  try {
    const callableFindUser = httpsCallable(functions, 'find_user_data_from_uid');
    const { data } = await callableFindUser(uid);

    const userAdminRef = databaseRef(adminDB, `/users/${uid}`);

    const userAdminSnapshot = await get(userAdminRef);
    const userAdminData = userAdminSnapshot.val();

    const usuarioData = {
      uid: data.uid,
      nombre: data.nombre || data.displayName,
      correo: data.correo || data.email,
      cuentas: data.cuentas || [],
      celular: data.celular || '',
      organizacion: data.organizacion || '',
      dispositivo: data.device_info || '',
      version: data.version_name ? data.version_name.toString() : '',
      nodo_raiz: data.nodo_raiz ? data.nodo_raiz.toString() : '',
      base_datos: data.base_datos ? data.base_datos.toString() : '',
      roles_admin: userAdminData ? userAdminData.roles : {},
      access_admin: Boolean(userAdminData)
    };

    return usuarioData;
  } catch (error) {
    console.error('Error finding user by UID:', error);
    throw new Error(error);
  }
};
const updateUser = async (infoNewUser) => {
  try {
    const callableUpdateUser = httpsCallable(functions, 'change_user_data_oc');
    const response = await callableUpdateUser(infoNewUser);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};


const getAccountById = async (accountId) => {
  try {
    const callableAccountID = httpsCallable(functions, 'get_account_by_id');
    const response = await callableAccountID(accountId);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};


const existsAccount = async (accountData) => {
  try {
    const callableAccountID = httpsCallable(functions, 'exists_account');
    const response = await callableAccountID(accountData);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};


  //Inactivar usuarios

  const inactivateUser = async (infoAccounts) => {
    const callableInactivate = httpsCallable(functions, 'inactivate_user');
    const response = await callableInactivate(infoAccounts);
    return response;
  };
  

  /**
   * ## update_user_admin_module
   *
   * Modificación del perfil y/o admin_cuenta para un usuario desde el módulo de administración de usuarios de Galapp.
   *
   * @param {object} data Objeto con las variables de la petición.
   * @param {string} data.uid Uid del usuario generado en Firebase Auth.
   * @param {string} data.perfil Nombre del perfil para el usuario. No es el id del perfil sino el nombre.
   * @param {string} data.admin_cuenta Propiedad para indicar si el usuario es administrador de la cuenta o no.
   * @param {string} data.base_datos Nombre de la base de datos de la cuenta activa. No es la url.
   * @param {string} data.nodo_raiz (Opcional) Ruta del nodo raíz dentro de la base de datos de la cuenta activa.
   */

  const updateUserAdmin = async (data) => {
    try {
      const callableUpdateUser = httpsCallable(functions, 'update_user_admin_account');
      const response = await callableUpdateUser(data);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  };
  

  // Traer las organizaciones
  const getOrganizations = async () => {
    try {
      const organizationsRef = databaseRef(defaultDb, '/organizaciones');
  
      const snapshot = await get(organizationsRef);
  
      if (snapshot.exists()) {
        return snapshot.val();
      } else {
        console.error('No organizations found in the database');
        return null;
      }
    } catch (error) {
      console.error('Error fetching organizations:', error);
      throw error;
    }
  };

  // Agregar un usuario existente a una cuenta

  const addUserToAccount = async (data) => {
    try {
      const callableAddUserToAccount = httpsCallable(functions, 'add_user_to_account');
      const response = await callableAddUserToAccount(data);
      return response.data;
    } catch (error) {
      throw new Error(`Error adding user to account: ${error.message}`);
    }
  };
  

  const changeDefaultAccount = async (data) => {
    try {
      const callableChangeDefaultAccount = httpsCallable(functions, 'change_default_account');
      const response = await callableChangeDefaultAccount(data);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  };
  

  const createUser = async (data) => {
    try {
      const callableCreateUser = httpsCallable(functions, 'create_user_admin_module');
      const response = await callableCreateUser(data);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  };
  

  const createOrganization = async (data) => {
    try {
      const callableCreateOrganization = httpsCallable(functions, 'create_organization');
      const response = await callableCreateOrganization(data);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  };
  

  /**
   * ## updateOrganization
   *
   * Función para actualizar una organización.
   *
   * @param {object} data Objetos con las variables de la petición
   * @param {string} data.dbId Identificador de la base de datos
   * @param {string} data.fechaModificacion Fecha en la que se hizo la actualización de la Organización
   * @param {Array}  data.cuentas Arreglo con las cuentas de la organización
   * @returns
   */

  const updateOrganization = async (data) => {
    try {
      const callableUpdateOrganization = httpsCallable(functions, 'update_organization');
      const response = await callableUpdateOrganization(data);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  };
  


  const addConfig = async (data) => {
    try {
      const callableAddConfiguration = httpsCallable(functions, 'add_configuration');
      const response = await callableAddConfiguration(data);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  };
  

  const getOrganizationById = async (data) => {
    try {
      const callableGetOrganization = httpsCallable(functions, 'get_organization_by_id');
      const response = await callableGetOrganization(data);
      return response.data;
    } catch (error) {
      console.error('Error getting organization by ID:', error);
      throw error;
    }
  };
  


  const getTemplates = async (data) => {
    try {
      const callableGetTemplates = httpsCallable( functions, 'get_templates_organizations_module');
      const response = await callableGetTemplates(data);
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  

  const sendEmailResetPassword = async (data) => {
    try {
      const callableResetPassword = httpsCallable(functions, 'reset_password_admin');
      const response = await callableResetPassword(data);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  };
  


  const callableFunction = async (nameFunction, data) => {
    try {
      const callable = httpsCallable(functions, nameFunction);
      const response = await callable(data);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  };
  

    /**
   * ## updateAdminAccessForUser
   *
   * Función para actualizar acceso del usuario a la base de datos del adminstrador
   *
   * @param {object} data Objetos con las variables de la petición
   * @returns
   */

    const updateAdminAccessForUser = async (userAccess, rolesSelected, user) => {  
        const userRef = databaseRef(adminDB, `/users/${user.uid}`);
      
        const userSnapshot = await get(userRef);
        const userAdmin = userSnapshot.val();
    
        let callableFunctionName;
        let actionName;

        const data = {
          fb_id: user.uid,
          roles: rolesSelected
        };
    
        switch (true) {
          case !userAdmin:
            callableFunctionName = 'create_user_admin';
            actionName = 'crear';
            break;
    
          case userAccess:
            callableFunctionName = 'edit_user_admin';
            actionName = 'editar';
            break;
    
          default:
            callableFunctionName = 'delete_user_admin';
            actionName = 'eliminar';
            delete data.roles;
            break;
        }
        const response = await callableFunction(callableFunctionName, data);
        return response.data.status != 'ok' ? {"status":"error", "message":`Error al ${actionName} usuario en el Administrador de Galápp`} : response.data; 

    };

    const restoreRecord = async (record) => {
      try {
        const callableRecordRestore = httpsCallable(functions, 'record_restore');
        const payload = { log: record };
        const response = await callableRecordRestore(payload);
        return response;
      } catch (error) {
        throw new Error(error);
      }
    };
    
  
    const getToken = async () => {
      try {
        const currentUser = auth.currentUser;
        if (currentUser) {
          const token = await getIdToken(currentUser);
          return token;
        } else {
          throw new Error('No user is currently signed in.');
        }
      } catch (error) {
        console.error('Error getting token:', error);
        return null;
      }
    };
    
  if (!isInitialized) return null;

  return (
    <FirebaseContext.Provider
      value={{
        auth,
        user,
        logout,
        setUser,
        storage,
        signinwith,
        initialize,
        isInitialized,
        getImg,
        saveImg,
        editImg,
        getStorageIcons,
        allTags,
        createTag,
        editTag,
        findUserByEmail,
        updateUser,
        getAccountById,
        inactivateUser,
        updateUserAdmin,
        getOrganizations, 
        addUserToAccount,
        changeDefaultAccount,
        createUser,
        createOrganization,
        addConfig,
        getOrganizationById,
        existsAccount,
        getTemplates,
        updateOrganization,
        sendEmailResetPassword,
        loginWithEmail,
        findUserByUid,
        getAllRolesAdmin,
        updateAdminAccessForUser,
        getAccountName,
        restoreRecord,
        getDocumentBackup,
        getToken,
      }}
      {...props}
    />
  );
}

function useFirebase() {
  const context = React.useContext(FirebaseContext);
  if (context === undefined) {
    throw new Error('useFirebase must be used within a FirebaseProvider');
  } else {
    return context;
  }
}

export { FirebaseProvider, useFirebase };
