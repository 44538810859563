

let settings;

let dev = {
  apiKey: "AIzaSyAph6qsAzhLrZGwwzBT82507YOI7q6TVa0",
  authDomain: "galapp-6b902.firebaseapp.com",
  databaseURL: "https://galapp-6b902.firebaseio.com",
  projectId: "galapp-6b902",
  storageBucket: "galapp-6b902.appspot.com",
  messagingSenderId: "1067914327266",
  appId: "1:1067914327266:web:2757925ab4723af9441abb",
  measurementId: "G-39KKZ5Z9V0"
};

let prod = {
  apiKey: "AIzaSyA-qMkBSRwY0biae_jqMhEZFtyUPGbctPU",
  authDomain: "appfinca-4cc7a.firebaseapp.com",
  databaseURL: "https://appfinca-4cc7a.firebaseio.com",
  projectId: "appfinca-4cc7a",
  storageBucket: "appfinca-4cc7a.appspot.com",
  messagingSenderId: "487630576275",
  appId: "1:487630576275:web:8d574503b7c56769140b66",
  measurementId: "G-D74PDTCYFF"
};

const isDev = (variable) => {
  return variable in process.env && process.env[variable].trim() === 'development';
};

if (isDev("NODE_ENV") || isDev("REACT_APP_GALAPP_ENV")) {
  settings = dev;
} else {
  settings = prod;
}

export default settings;
