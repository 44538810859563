import React from 'react';
import PropTypes from 'prop-types';
import { Button as MaterialButton } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import './AppButton.scss';

export const BUTTON_TYPES = {
  BUSCAR: 'buscar',
  MODAL_AMARILLOS: 'modal-amarillos',
  MODAL_CONTAINED: 'modal-contained',
  YELLOW_BUTTON: 'yellow-button',
  DEFAULT: 'default',
};

const StyledButtonBuscar = styled(MaterialButton)({
  background: "#ffb81d",
  borderRadius: 3,
  color: "#000000",
  height: 48,
  padding: "0 30px",
  justifyContent: "center",
  "&:hover": {
    background: "#ffb81d",
  },
});

const StyledButtonModalYellow = styled(MaterialButton)({
  background: "#ffb81d",
  border: 0,
  borderRadius: 3,
  color: "#000000",
  height: 38,
  padding: "0 12px",
  marginBottom: "30px",
  "&:hover": {
    background: "#ffb81d",
  },
});

const StyledButtonModalGray = styled(MaterialButton)({
  border: 0,
  borderRadius: 3,
  color: "#000000",
  background: "#e0e0e0",
  height: 38,
  padding: "0 12px",
});

const StyledButtonContained = styled(MaterialButton)({
  border: 0,
  borderRadius: 3,
  height: 38,
  padding: "0 10px",
  marginBottom: "0px",
  marginLeft: "50px",
});

const StyledButtonYellow = styled(MaterialButton)({
  background: "#ffb81d",
  border: 0,
  borderRadius: 3,
  color: "#000000",
  height: 38,
  padding: "0 12px",
  "&:hover": {
    background: "#ffb81d",
  },
});

function Button({ children, loading, tipo, ...props }) {
  const renderButtonContent = () =>
    loading ? <CircularProgress color="inherit" size={20} /> : children;

  switch (tipo) {
    case BUTTON_TYPES.BUSCAR:
      return <StyledButtonBuscar {...props}>{renderButtonContent()}</StyledButtonBuscar>;
    case BUTTON_TYPES.MODAL_AMARILLOS:
      return <StyledButtonModalYellow {...props}>{renderButtonContent()}</StyledButtonModalYellow>;
    case BUTTON_TYPES.MODAL_CONTAINED:
      return <StyledButtonContained {...props}>{renderButtonContent()}</StyledButtonContained>;
    case BUTTON_TYPES.YELLOW_BUTTON:
      return <StyledButtonYellow {...props}>{renderButtonContent()}</StyledButtonYellow>;
    default:
      return <StyledButtonModalGray {...props}>{renderButtonContent()}</StyledButtonModalGray>;
  }
}

Button.propTypes = {
  children: PropTypes.any,
  loading: PropTypes.bool,
  tipo: PropTypes.oneOf(Object.values(BUTTON_TYPES)),
};

export default Button;
