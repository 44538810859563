import React from "react";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FormError from '../../FormError/AppFormError';

import './AppSelectMultiple.scss';

const AppSelectMultiple = ({
    id,
    name,
    options,
    label,
    onChange,
    value,
    disabled,
    errors,
    errorMessage,
    ...props
}) => {
    // Función para agregar el item 'Todas'
    const addAllOption = (array) => {
        return [{ value: 'Todas', label: 'Todas' }, ...array ];
    };

    // Filtrar opciones que no tienen label y agregar 'Todas'
    const filteredOptions = addAllOption(options ?? [].filter(option => option.label));

    const handleSelectAll = () => {
        onChange(filteredOptions.filter(option => option.value !== 'Todas'));
    };

    return (
        <div className='select-input'>
            <FormControl fullWidth>
                <div className="container-selected">
                    <Autocomplete
                        id={id}
                        name={name}
                        disabled={disabled || false}
                        multiple
                        disableCloseOnSelect
                        value={value}
                        onChange={(e, newValue) => {
                            if (newValue.some(option => option.value === 'Todas')) {
                                handleSelectAll();
                            } else {
                                onChange(newValue);
                            }
                        }}
                        options={filteredOptions}
                        getOptionLabel={option => option.label}
                        renderInput={params => (
                            <TextField label={label} {...params} variant='standard' />
                        )}
                        renderOption={(props, option, { selected }) => (
                            <div {...props} className={`options-value`}>
                                {selected && <CheckCircleIcon fontSize="small" />}
                                {option.label}
                            </div>
                        )}
                    />
                </div>
            </FormControl>
            <FormError errorMessage={errorMessage} />
        </div>
    );
};

export default AppSelectMultiple;
