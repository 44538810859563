import React, { useEffect, useState } from 'react';
import { useFirebase } from '../../core/context/firebase-context';

import Button from '@mui/material/Button';
import './index.scss';
import logo from '../../assets/galapp-logo.png'; // Tell webpack this JS file uses this image
import SelectTypeVerification from './TwoStepAuthentication/SelectTypeVerification';
import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import {
  getMultiFactorResolver,
  PhoneMultiFactorGenerator,
} from 'firebase/auth';
import * as Yup from 'yup';


import AppText from '../../core/components/Input/Text/AppText';
import AppPassword from '../../core/components/Input/Password/AppPasword';
import AppErrorMessage from '../../core/components/ErrorMessage/AppErrorMessage';



function Login() {
  const firebase = useFirebase();
  const [hasTwoStepAuthentication, setHasTwoStepAuthentication] = useState(false);
  const [resolver2FA, setResolver] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if(!firebase.isInitialized
      && firebase.user == null && firebase.userPermissions == null) { 
      setErrorMessage('Acceso denegado');
    }

  }, [firebase.user, firebase.userPermissions]);
  

  const handleAuthError = (error) => {
    let resolver;
    try {
      resolver = getMultiFactorResolver(firebase.auth, error);
    } catch (e) {
      console.error('No MultiFactorResolver found:', e);
    }
    switch (error.code) {
      case 'auth/multi-factor-auth-required':
        // Ask user which second factor to use.
        if (resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
          setResolver(resolver);
          setHasTwoStepAuthentication(true);
        }
        break;
      default:
        console.error(error);
        break;
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      setIsLoadingGoogle(true);
      await firebase.signinwith();
      navigate('/');
    } catch (error) {
      setLoading(false);
      error.code == 'auth/account-exists-with-different-credential'
        ? alert('Este correo ya existe con otro proveedor')
        : handleAuthError(error);
    }
  };
  const handleEmailSignIn = async ({email, password}, actions) => {
    try {
      setLoading(true);
      await firebase.loginWithEmail({email, password});
      !firebase.userAdmin && setErrorMessage('Acceso denegado');
    } catch (error) {
      setLoading(false);
      switch (error.code) {
          case 'auth/wrong-password':
              actions.setErrors({password: 'Contraseña incorrecta'});
              break;
          case 'auth/user-not-found':
              actions.setErrors({email: 'Correo incorrecto'});
              break;
          default:
              setErrorMessage('Acceso denegado');
              break;
      }
  }
  };

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Correo inválido')
      .required('Correo requerido'),
    password: Yup.string().required('Contraseña requerida'),
  });


  return (
    <section className='login'>
      <div className='container'>
        <div className='logo'>
          <img src={logo} alt='galapp' />
          <h3 className='admin-name'>ADMINISTRADOR</h3>
        </div>
        {!hasTwoStepAuthentication ? (
          <>
          <Formik
            initialValues={{ email: '', password: '' }}
            onSubmit={handleEmailSignIn}
            validationSchema={LoginSchema}
          >
            {({ errors, actions, values, touched, handleChange }) => {
                  return (
                    <Form>
                      <div className="login-form">
                        <div className='container-textFields'>
                          <AppText
                            id='email'
                            name='email'
                            label='Correo'
                            value={values.email}
                            errors={touched.email && !!errors.email}
                            errorMessage={touched.email && errors.email}
                            onChange={handleChange}
                          />
                          <AppPassword
                            name='password'
                            label='Contraseña'
                            autoComplete='off'
                            value={values.password}
                            onChange={handleChange}
                            errors={touched.password && !!errors.password}
                            errorMessage={
                              touched.password && errors.password
                            }
                          />                      
                        </div>

                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          id="sign-in-button"
                          className='btn-email'
                          loading={loading}
                        >
                          Acceder con Correo
                        </Button>

                        <Button
                          variant="contained"
                          color="secondary"
                          className='btn-google'
                          loading ={isLoadingGoogle}
                          onClick={() => handleGoogleSignIn(actions)}
                        >
                          Acceder con Google
                        </Button>
                        {errorMessage && (
                          <AppErrorMessage
                          message={errorMessage}
                          setMessage={setErrorMessage}
                        ></AppErrorMessage>
                        )}{' '}
                      </div>
                    </Form>
                  );
                }}

          </Formik>
          </>
        ) : (
          <>
            <SelectTypeVerification resolver={resolver2FA} />
          </>
        )}
      </div>
    </section>
  );
}

export default Login;