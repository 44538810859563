import React from 'react';
import { ButtonGroup } from '@mui/material';
import { styled } from '@mui/system';

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'right',
  marginTop: '20px',
  marginBottom: '20px',
  '& > *:not(:last-child)': {
    marginRight: theme.spacing(2),
  },
}));

const ButtonGroupComponent = ({ children }) => {
  return (
    <StyledButtonGroup aria-label="full width outlined button group">
      {children}
    </StyledButtonGroup>
  );
};
  export default  ButtonGroupComponent;