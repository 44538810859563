import React from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ButtonBuscar from '../Button/AppButton';
import Grid from '@mui/material/Grid2';

function ButtonCreateAccount({
    disabled,
    loading,
    create,

}) {

    return (
        <div className='container'>
            <Grid container direction='column' spacing={4}>
                <Grid container justifyContent="flex-end"> 
                    <div>
                        <div className='popup__button'>
                            <ButtonBuscar
                                variant='contained'
                                color='primary'
                                tipo='yellow-button'
                                disabled={disabled}
                                loading={loading}
                                startIcon={<AddCircleOutlineIcon />}
                                onClick={() => create()}
                            >
                                Nueva
                            </ButtonBuscar>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}


export default ButtonCreateAccount;