import React from 'react';
import { Dialog } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';



import './AppPopup.scss';

function Popup({
  title,
  children,
  onClose,
  open,
  setPopupInfoImage,
  ...props
}) {


  return (
    <div className='popup'>
      <Dialog
        className='modal'
        open={open}
        onClose={onClose}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
      >
        <AppBar color="primary" position="static" >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 1, flex: 1 }} variant="h6" component="div">
              {title}
            </Typography>

          </Toolbar>
        </AppBar>    
         <DialogContent dividers>

        <div className='popup-body'>{children}</div>
        </DialogContent>

      </Dialog>

    </div >
  );
}

export default Popup;
