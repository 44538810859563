import React from 'react';
import List from '@mui/material/Card';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Checkbox from '@mui/material/Checkbox';
import ButtonBuscar from '../../Button/AppButton';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

import './AppList.scss';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

function ListField({
  data,
  disabled,
  title,
  loading,
  create,
  colorTextData,
  newAccount,
  newUser,
  selected,
  onSelected,
  onClick,
}) {
  const [show, setShow] = React.useState(true);

  const contador = data.length;

  return (
    <div className='container'>
      <Grid container columnSpacing={0} rowSpacing={0} direction='column' spacing={4}>
        <Grid>
          <Typography component='legend'>{title}</Typography>
        </Grid>
        <Grid>
          <div className='list_table'>
            {show ? (
              <List
                className={
                  contador !== 0 ? 'ListItems-New' : 'ListItems-New-Mas'
                }
                component='nav'
                aria-label='contacts'
              >
                <div className='list-field-items'>
                  {Object.keys(data).length === 0 || data === undefined ? (
                    <ul>
                      <div
                        className='count'
                      >
                        Número de elementos: {contador}
                        <div className='popup__button'>

                        {!disabled && (
                          <ButtonBuscar
                            variant='contained'
                            color='primary'
                            tipo='yellow-button'
                            loading={loading}
                            startIcon={<AddCircleOutlineIcon />}
                            onClick={() => create()}
                          >
                            Nueva
                          </ButtonBuscar>
                        )}

                        </div>
                      </div>
                    </ul>
                  ) : (
                    <ul>
                      {Object.keys(data).map((key, index) => {
                        const bdtext = data[key].bodyText && data[key].bodyText[0] ? data[key].bodyText[0] : '';
                        const headerText = data[key].headerText;
                        const bodyText = data[key].bodyText && data[key].bodyText[1]
                          ? `${bdtext}:  ${data[key].bodyText[1]}`
                          : bdtext;

                        const colorText =
                          colorTextData &&
                            data[key].idList === colorTextData[0]
                            ? colorTextData[1]
                            : '';
                        return (
                          <li
                            key={index}
                            className={
                              colorTextData &&
                                data[key].idList === colorTextData[0]
                                ? 'li_default'
                                : ''
                            }
                          >
                            {newUser || newAccount ? (
                              ''
                            ) : (
                              <div className='check_boxes'>
                                {disabled ? (
                                  ''
                                ) : (
                                  <div className='check_boxes'>
                                    <Checkbox
                                      idList={data[key].idList}
                                      value={data[key].idList}
                                      checked={
                                        selected ? selected.includes(data[key].idList) : false
                                      }
                                      onChange={onSelected}
                                    />
                                  </div>
                                )}

                              </div>
                            )}

                            <div
                              className='textItem'
                              onClick={() => onClick(data[key])}
                            >
                              <div className='encabezado'>{headerText}</div>
                              <div className='adicional'>
                                {bodyText}{' '}
                                <span className='colorText'>{colorText}</span>
                              </div>
                            </div>
                            {onClick ? (
                              <div onClick={() => onClick(data[key])}>
                                <ChevronRightIcon />
                              </div>
                            ) : (
                              ''
                            )}
                          </li>
                        );
                      })}
                      <div
                        className={contador !== 0 ? 'count' : 'countNoButton'}
                      >
                        Número de elementos: {contador}
                        <div className='popup__button'>
                          {disabled ? (
                            ''
                          ) : (
                            <ButtonBuscar
                              variant='contained'
                              color='primary'
                              tipo='yellow-button'
                              disabled={disabled}
                              loading={loading}
                              startIcon={<AddCircleOutlineIcon />}
                              onClick={() => create()}
                            >
                              Nueva
                            </ButtonBuscar>
                          )}
                        </div>
                      </div>
                    </ul>
                  )}
                </div>
              </List>
            ) : (
              <></>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}


export default ListField;