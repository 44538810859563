import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import landing from './landing.json';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import { makeStyles } from '@mui/styles';
import './AppPhone.scss';
import Button from '../../Button/AppButton';




function TelefonoField({ defaultCountryCode, ...props }) {
  const plus = '+';

  const [countryDefault, setCountryDefault] = React.useState(
    landing[defaultCountryCode] ? landing[defaultCountryCode] : '57'
  );

  const lada = plus.concat(countryDefault);

  const [phone, setPhone] = React.useState(typeof props.value === 'number' ? props.value.toString() : props.value);
  const classes = useStyles();

  const handleChange = (event) => {
    if (event.charAt(0) !== '+') {
      setPhone(plus.concat(event));
      props.onChange(plus.concat(event));
    }
  };

  React.useEffect(() => {
    if (phone === '') {
      setPhone(plus.concat(countryDefault));
      props.onChange(plus.concat(countryDefault));
    }
    else if (!phone.startsWith('+')) {
      setPhone(lada.concat(props.value));
      props.onChange(lada.concat(props.value));
    }
  }, [props.disabled]);


  const whatsAppPhone = phone.split('+')[1];

  const hrefWhats = `https://api.whatsapp.com/send?phone=${whatsAppPhone}&text=&source=&data=&app_absent=`;
  const hrefCall = `tel:${phone}`;


  return (
    <div className="phone_component container_phone">
      <div className='phone_input_component'>
        <PhoneInput
              inputStyle={{ width: '200px' }} // Overwrite width here
              autoFocus={true}
              country={defaultCountryCode ? defaultCountryCode.toLowerCase() : 'co'}
              value={phone}
              specialLabel='Teléfono'
              onChange={handleChange}
              enableSearch={true}
              autoFormat={false}              
              disabled={props.disabled}
        />
      </div>
     
      <div className="phone-buttons container_phone_buttons">
        <Button variant="contained"
          className='buttonPhone'
          startIcon={<PhoneIcon />}
          href={hrefCall}
       >
        </Button>

        <Button variant="contained" 
          className='buttonWhatsApp'
          startIcon={<WhatsAppIcon />}
          href={hrefWhats}
          target ="_blank" 
          rel="noopener noreferrer"
          >
       
        </Button>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  startIcon: {
    margin: 0,
  }

}));


export default TelefonoField;
