import React, {useState} from 'react';

import {Form, Formik} from 'formik';
import * as Yup from 'yup';

import AppPassword from '../../core/components/Input/Password/AppPasword';

//Componentes core
import AppPopup from '../../core/components/Popup/AppPopup';
import AppButton,{BUTTON_TYPES} from '../../core/components/Button/AppButton';
import AppButtonGroup from '../../core/components/ButtonGroup/AppButtonGroup';
import AppText from '../../core/components/Input/Text/AppText';
import AppSnackbar from '../../core/components/Snackbar/AppSnackbar';

import './PopupPasswordChanged.scss';

/**
 * A component for displaying a password change confirmation popup.
 *
 * @param {object} props - Component props.
 * @param {Function} props.handleClose - Function to handle closing the popup.
 * @param {boolean} props.open - Whether the popup is open.
 * @param {string} props.password - The user's password.
 * @param {string} props.email - The user's email.
 */

const PopoupPasswordChanged = ({handleClose, open, password, email}) => {
  const [openAlert, setOpenAlert] = useState(false);

  /**
   * Copies the user's email and password to the clipboard and triggers an alert.
   *
   * @param {object} values - Formik form values containing email and password.
   */
  const copyText = (values) => {
    const cleanText = `${values.email}\n ${values.password}`;
    navigator.clipboard.writeText(cleanText);
    handleAlert();
  };

  /**
   * Handles showing or hiding the alert for successful copying of user data.
   */
  const handleAlert = () => {
    setOpenAlert(!openAlert);
  };

  return (
    <section className='search'>
      <div className='modal'>
        <Formik
          initialValues={{
            email: email,
            password: password,
          }}
          enableReinitialize={true}
          validationSchema={Yup.object({
            new_password: Yup.string()
              .max(40, 'Debe tener 40 caracteres o menos')
              .required('Campo requerido'),
          })}
          onSubmit={() => {}}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <AppPopup
                title='Contraseña cambiada'
                onClose={handleClose}
                open={open}
              >
                <AppText
                  name='email'
                  type='email'
                  label='Correo electrónico'
                  autoComplete='off'
                  disabled={true}
                  value={formik.values.email}
                  errors={formik.touched.email && !!formik.errors.email}
                  errorMessage={formik.touched.email && formik.errors.email}
                />
                <AppPassword
                  name='password'
                  label='Contraseña'
                  autoComplete='off'
                  disabled={true}
                  value={formik.values.password}
                  errors={formik.touched.password && !!formik.errors.password}
                  errorMessage={
                    formik.touched.password && formik.errors.password
                  }
                />

                <div
                  className='copy-user-data'
                  onClick={() => copyText(formik.values)}
                >
                  <span>COPIAR DATOS</span>
                </div>


                <div className='notes'>
                  <span>Se envió un correo al usuario informandole el
                  restablecimiento de su contraseña.</span>
                </div>

                <AppButtonGroup
                  variant='contained'
                  aria-label='outlined primary button group'
                >
                  <AppButton
                    variant='contained'
                    tipo={BUTTON_TYPES.DEFAULT}
                    onClick={handleClose}
                  >
                    Cerrar
                  </AppButton>
                </AppButtonGroup>
              </AppPopup>
            </Form>
          )}
        </Formik>
      </div>
      <AppSnackbar
        open={openAlert}
        onClose={() => handleAlert()}
        style='success'
      >
        ¡Se han copiado los datos del usuario!
      </AppSnackbar>
    </section>
  );
};

export default PopoupPasswordChanged;
