import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const ERROR_MESSAGE_TIMEOUT = 6000;

function ErrorMessage({ message, setMessage }) {
  useEffect(() => {
    const clearMessage = () => {
      const timeoutId = setTimeout(() => {
        setMessage('');
      }, ERROR_MESSAGE_TIMEOUT);

      return () => clearTimeout(timeoutId);
    };

    if (message) {
      clearMessage();
    }
  }, [message, setMessage]);

  return message ? <div className="form-error">{message}</div> : null;
}

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
  setMessage: PropTypes.func.isRequired,
};

export default ErrorMessage;
