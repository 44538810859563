import React from 'react';
import Select from "react-select/creatable";
import InputLabel from '@mui/material/InputLabel';
import { FormControl } from '@mui/material';
import FormError from '../../FormError/AppFormError';
import './AppMultiListField.scss';

function AppMultiListField({ label,id,name,errors, errorMessage, ...props }) {
  const handleSelectChange = (selectedValues) => {
    const names = selectedValues ? selectedValues.map(item => item.value) : [];
    const result = names.length > 0 ? names : '';
    props.onChange(result);
  };

  const newobj = (props.value || []).map(element => ({
    label: element,
    value: element,
    _isNew_: false,
  }));


  const formatCreateLabel = inputValue => {
    return `Crear "${inputValue}"`;
  };

  
  return (
    <div className="multi_list">
      <FormControl fullWidth>
      <InputLabel className='cont-lab' id="text-component">{label}</InputLabel>
      <Select
        id={id}
        name={name}
        error={errors}
        className="react-select"
        classNamePrefix="react-select"
        value={newobj}
        isMulti={true}
        isDisabled={props.disabled}
        onChange={handleSelectChange}
        noOptionsMessage={() => ('Ingrese texto')}
        formatCreateLabel={formatCreateLabel}
      />
      </FormControl>
      <FormError errorMessage={errorMessage} />
    </div>
    
  );

}


export default AppMultiListField;




