import React from 'react';
import CardImage from './components/CardImage';
import './CardsImages.scss';

const CardsImages = ({infoData, onClickCard}) => {
  return (
    <div className='container-cards'>
      {infoData.map((item, key) => (
        <CardImage key={key} data={item} onClickCard={onClickCard} />
      ))}
    </div>
  );
};

export default CardsImages;