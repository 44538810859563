import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormError from '../../FormError/AppFormError';
import './AppSelectMultiple.scss';

function SelectField({ id, name, label, options, value, errors, onChange, disabled, errorMessage, ...props }) {

  return (
    
    <div className="select-input">
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label" variant='standard'>{label}</InputLabel>
        <Select
          label={label}
          id={id}
          value={value}
          name={name}
          variant='standard'
          onChange={onChange}
          error={errors}
          disabled={disabled ? disabled : false}
          
        >
          {options.map(option => (
            
            <MenuItem
              key={option.value}
              value={option.value}
              className='menu-item-select'
            >
             <div className='menu-item-select'>{option.label}</div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormError errorMessage={errorMessage} />
    </div>
  );
}


export default SelectField;
