import React from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from "@mui/material/Typography";


function TableField({title, data, ...props }) {
  return (
    <TableContainer
    component={Paper}
    >
   <Table aria-label="simple table">
      <TableHead>
        <TableRow>
        <TableCell align="center" colSpan={2}>
          <Typography  style={{ fontWeight: 600 }}> {title}</Typography>                    
        </TableCell>
        </TableRow>
      </TableHead>  
        <TableBody>
        {Object.keys(data).map((key,index)=>{
          return(
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" size='small'>
                {key}
              </TableCell>
              <TableCell align="left" size='small'>{data[key]}</TableCell>
            </TableRow>
          );
        })}
            
        </TableBody>
     </Table>
   </TableContainer>
    
  );
}



export default TableField;