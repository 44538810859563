/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, {useEffect, useState} from 'react';

import FormLabel from '@mui/material/FormLabel';

// Componentes core
import AppPopup from '../../core/components/Popup/AppPopup';
import AppButtonGroup from '../../core/components/ButtonGroup/AppButtonGroup.js';
import AppButton,{BUTTON_TYPES} from '../../core/components/Button/AppButton.js';
import AppText from '../../core/components/Input/Text/AppText';
import AppSelect from '../../core/components/Input/Select/AppSelect';
import AppSwitch from '../../core/components/Input/Switch/AppSwitch';
import AppMultiListField from '../../core/components/Input/Text/AppMultiListField';
import AppSelectMultiple from '../../core/components/Input/Select/AppSelectMultiple';

import {useLocation} from "react-router-dom";
// Formik
import {Formik, Form} from 'formik';
import * as Yup from 'yup';

// Utilidades
import {formatOrganizations, formatProfiles} from './utils/formatArrayOptions';

// Hooks
import {useAccountFromTemplate} from './hooks/useAccountFromTemplate';
import useFetchAccountData from './hooks/useFetchAccountData';
import useUserManager from '../Users/hooks/useUserManager';


const accountTypes = [
  {value: 'testing', label: 'Pruebas'},
  {value: 'development', label: 'Desarrollo'},
  {value: 'production', label: 'Producción'},
];

/**
 * Represents a popup for creating a new account.
 * @param {Object} props - The component's props.
 * @param {Function} props.handleClose - A function to close the popup.
 * @param {boolean} props.open - A boolean indicating whether the popup is open.
 * @param {Object} props.accountSelected - The selected account information.
 * @param {string} props.nameOrganization - The name of the organization.
 * @param {Function} props.handleSnackBar - A function to handle the display of snackbars.
 */

function PopupNewAccount({
  handleClose,
  open,
  accountSelected,
  handleSnackBar,
  onSubmit,
  organizations,
  dbId,
  newOrganization,
}) {
  const [loading, setLoading] = useState(false);
  const [accountsTemplate, setAccountsTemplate] = useState([]);
  const [accountProfiles, setAccountProfiles] = useState([]);

  const [enableGeneric, setEnableGeneric] = useState(false);
  const [enableExistingAccount, setEnableExistingAccount] = useState(false);

  const {getUserByEmail, getAccountWithId} = useUserManager();

  const { templates, profiles, getTemplatesInfo } = useAccountFromTemplate();

  const {availableForms, getForms} = useFetchAccountData(
    organizations && organizations[dbId]
  );

  const location = useLocation();

  useEffect(() => {
    open && getTemplatesInfo();
    !open && loading && setLoading(false);
  }, [open]);

  const getAccountsTemplate = () => {
    if (!organizations || !dbId || !organizations[dbId].cuentas) {
      return [];
    }
    const {cuentas} = organizations[dbId];

    return Object.keys(cuentas).map((key) => ({
      value: key,
      label: cuentas[key].nombre,
    }));
  };

  const initialAccountValues = {
    accountType: accountSelected?.accountType || '',
    name: accountSelected?.headerText || '',
    rootNode: accountSelected?.rootNode || '',
    idElement: accountSelected?.idElement || '',
    organizationId: organizations && dbId ? dbId : '',
    template: accountSelected?.template || '',
    sqlId: accountSelected?.sqlId || '',
    initialUserEmail:
      accountSelected?.initialUserEmail ||
      'servicioalcliente@galapagoagro.co',
    initialUserProfile: accountSelected?.initialUserProfile || '',
    forms_selected: accountSelected?.forms_selected || [],
    enableGeneric: accountSelected?.enableGeneric || false,
    enableExistingAccount: accountSelected?.enableExistingAccount || false,
  };

  const validationObject = Yup.object({
    accountType: Yup.string().required('Campo requerido'),
    name: Yup.array()
      .required('Campo requerido')
      .of(
        Yup.string().max(
          70,
          'El identificador debe ser máximo de 70 caracteres'
        )
      )
      .test({
        name: 'name',
        test: function (value) {
          if (Array.isArray(value) && value.length > 0) {
            const nameValue = value.length;
            const idValue =
              this.parent.rootNode && this.parent.rootNode.length;

            if (nameValue === idValue) {
              return true;
            } else {
              return this.createError({
                message:
                  'Debe ingresar la misma cantidad de nombres en el ID cuenta',
              });
            }
          }
          return this.createError({
            message: 'Debe ingresar al menos un nombre de cuenta',
          });
        },
      }),
      rootNode: Yup.array()
      .when('accountType', {
        is: 'production',
        then: Yup.array().of(
          Yup.string()
            .matches(
              /^[a-zA-Z0-9_-]+$/,
              "El id de la cuenta solo debe tener letras, números, '-' y/o '_'"
            )
            .max(30, 'El identificador debe ser máximo de 30 caracteres')
            .required('Campo requerido')
        ),
        otherwise: Yup.array().of(
          Yup.string()
            .matches(
              /^[a-z_-]+$/,
              "El id de la cuenta solo debe tener letras minusculas, '-' y/o '_'"
            )
            .max(30, 'El identificador debe ser máximo de 30 caracteres')
            .required('Campo requerido')
        )
      })
      .required('Debe ingresar al menos un Id de cuenta')
      .test(
        'root-node-length',
        'Debe ingresar la misma cantidad de nombres en el nombre de cuenta',
        function (value) {
          if (Array.isArray(value) && value.length > 0) {
            const idValueLength = value.length;
            const nameValueLength = this.parent.name
              ? this.parent.name.length
              : 0;
    
            if (idValueLength === nameValueLength) {
              return true;
            }
          }
          return this.createError({
            message: 'Debe ingresar al menos un Id de cuenta',
          });
        }
      ),
    template: Yup.string().required('Campo requerido'),
    sqlId: Yup.string()
      .required('Campo requerido')
      .matches(
        /^[a-z|A-Z|_]+$/,
        "El identificador solo debe tener letras y/o '_'"
      )
      .max(10, 'El sqlId debe ser máximo de 10 caracteres'),
    initialUserEmail: Yup.string()
      .required('Campo requerido')
      .email('Formato de correo electrónico inválido'),
    initialUserProfile: Yup.string().required('Campo requerido'),
    forms_selected: Yup.array().test({
      name: 'forms',
      test: function (value) {
        if (this.parent.enableExistingAccount === true) {
          if (Array.isArray(value) && value.length) {
            if (
              availableForms.length === 1 &&
              availableForms[0] === 'Todas' &&
              this.parent.enableExistingAccount === true
            ) {
              return this.createError({message: 'Elija otra cuenta'});
            } else {
              return true;
            }
          } else if (
            availableForms.length > 1 ||
            this.parent.template === undefined
          ) {
            return this.createError({message: 'Seleccione un formulario'});
          }
        }
        return true;
      },
    }),
  });

  const handleOnSubmit = async (values) => {
    setLoading(true);

    const response = await getUserByEmail(values.initialUserEmail);

    if (response.error) {
      handleSnackBar({
        message: 'No existe un usuario con el correo ingresado',
        style: 'warning',
      });
      setLoading(false);
      return;
    }

    const getDatabase = (type) => {
      switch (type) {
        case 'development':
          return process.env.REACT_APP_DB_DEV;
        case 'testing':
          return process.env.REACT_APP_DB_TEST;
        case 'production':
          return process.env.REACT_APP_DB_PROD;
        default:
          throw new Error(`Unknown account type: ${type}`);
      }
    };

    const database = getDatabase(values.accountType);

    const responses = await Promise.all(
      values.rootNode.map(async (node) => {
        const adjustedNodoRaiz =
          values.accountType === 'production' ? `clientes>${node}` : node;
        const idAccount = `${database}@${adjustedNodoRaiz}`;
        const data = await getAccountWithId(idAccount);
        return {account: node, response: data};
      })
    );

    const filteredResponses = responses.filter(
      ({response}) => response !== null
    );
    if (filteredResponses.length > 0) {
      const nodes = filteredResponses.reduce(
        (acc, {account}) => acc + account + ', ',
        ''
      );

      const finalNodes = nodes.slice(0, -2);

      finalNodes &&
        handleSnackBar({
          message: `Error: Ya existe ${
            filteredResponses.length === 1 ? 'una cuenta' : 'cuentas'
          } con ${filteredResponses.length === 1 ? 'el' : 'los'} identificador${
            filteredResponses.length === 1 ? '' : 'es'
          } ${finalNodes}`,
          style: 'warning',
        });
      setLoading(false);
      return;
    }

    if (values.forms_selected) {
      const allSelected = values.forms_selected.length === availableForms.length;
      enableExistingAccount ? values['forms'] = allSelected ? ["All"] : values.forms_selected.map((form) => form.value) : values['forms'] = [];
    }

    if (!newOrganization) {
      values['organization'] = organizations[dbId].nombre;
    }

    try {
      await onSubmit({...accountSelected, ...values});
      handleClose();
      if (location.pathname.startsWith('/organizations/')) {
        window.location.reload();
      }
    } catch (error) {
      handleSnackBar({
        message: error.error || 'Error creando la cuenta, intentelo más tarde',
        style: 'warning',
      });
    } finally {
      setLoading(false);

    }
  };

  const handleToggleGeneric = async (value) => {
    setEnableGeneric(value);
    if (value) {
      setEnableExistingAccount(false);
      await getTemplatesInfo();
    }
  };

  const handleToggleExistingAccount = (value) => {
    setEnableExistingAccount(value);
    if (value) {
      setEnableGeneric(false);
      const accsTemp = getAccountsTemplate();
      setAccountsTemplate(accsTemp);
    }
  };

  const formatErroMessage = (error) => {
    return Array.isArray(error) ? error.join(', ') : error;
  };

  return (
    <section>
      <AppPopup title='Nueva cuenta' onClose={handleClose} open={open}>
        <Formik
          initialValues={initialAccountValues}
          enableReinitialize
          validationSchema={validationObject}
          onSubmit={(values) => {
            handleOnSubmit(values);
          }}
        >
          {(formik) => (
            <Form
              style={{display: "flex", flexDirection: "column", gap: "30px"}}
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit(e);
              }}
            >
              <AppSelect
                id='accountType'
                name='accountType'
                label='Tipo de cuenta'
                options={
                  newOrganization
                    ? accountTypes.filter(
                        (accountType) => accountType.value !== 'production'
                      )
                    : accountTypes
                }
                value={formik.values.accountType}
                errors={
                  formik.touched.accountType && !!formik.errors.accountType
                }
                errorMessage={
                  formik.touched.accountType && formik.errors.accountType
                }
                onChange={formik.handleChange}
              />
              <AppMultiListField
                id='name'
                name='name'
                label='Nombre de cuenta'
                variant='outlined'
                value={formik.values.name}
                errors={formik.touched.name && !!formik.errors.name}
                errorMessage={
                  formik.touched.name && formatErroMessage(formik.errors.name)
                }
                onChange={(value) => {
                  formik.setFieldValue('name', value);
                }}
              />
              <AppMultiListField
                id='rootNode'
                name='rootNode'
                label='Id cuenta: Ej. lev, bacao, bayas_andes'
                variant='outlined'
                value={formik.values.rootNode}
                errors={formik.touched.rootNode && !!formik.errors.rootNode}
                errorMessage={
                  formik.touched.rootNode &&
                  formatErroMessage(formik.errors.rootNode)
                }
                onChange={(value) => {
                  formik.setFieldValue('rootNode', value);
                }}
              />

              <AppSelect
                id='organizationId'
                name='organizationId'
                label='Organización'
                options={
                  organizations ? formatOrganizations(organizations) : []
                }
                disabled
                onChange={formik.handleChange}
                value={formik.values.organizationId}
                errors={
                  formik.touched.organizationId &&
                  !!formik.errors.organizationId
                }
                errorMessage={
                  formik.touched.organizationId &&
                  formik.errors.organizationId
                }
              />
              {!newOrganization && (
                <div className='template-type'>
                  <FormLabel>Tipo de plantilla</FormLabel>
                  <AppSwitch
                    name='enableGeneric'
                    label='Genérica'
                    labelLeft='No'
                    labelRight='Sí'
                    className='switch-button'
                    checked={enableGeneric}
                    value={enableGeneric}
                    onChange={(e) => handleToggleGeneric(e)}
                  />

                  <AppSwitch
                    name='enableExistingAccount'
                    label='Cuenta existente'
                    labelLeft='No'
                    labelRight='Sí'
                    className='switch-button'
                    checked={enableExistingAccount}
                    value={enableExistingAccount}
                    onChange={(e) => handleToggleExistingAccount(e)}
                  />
                </div>
              )}

              <AppSelect
                id='template'
                name='template'
                label='Plantilla línea de negocio'
                options={
                  (enableGeneric && templates) ||
                  (enableExistingAccount && accountsTemplate) ||
                  (newOrganization && templates) ||
                  []
                }
                value={formik.values.template}
                errors={formik.touched.template && !!formik.errors.template}
                errorMessage={formik.touched.template && formik.errors.template}
                onChange={async (e) => {
                  formik.setFieldValue('template', e.target.value);
                  if (enableExistingAccount) {
                    getForms(e.target.value);
                    const {perfiles} = await getAccountWithId(e.target.value);
                    setAccountProfiles(
                      perfiles.map((accountProfile) => ({
                        value: accountProfile.id,
                        label: accountProfile.nombre,
                      }))
                    );
                  }
                }}
              />
              {enableExistingAccount === true && (
                <>
                  <AppSelectMultiple
                    id='forms_selected'
                    name='forms_selected'
                    options={availableForms}
                    label='Formularios'
                    value={formik.values.forms_selected}
                    disabled={false}
                    errors={
                      formik.touched.forms_selected &&
                      !!formik.errors.forms_selected
                    }
                    errorMessage={
                      formik.touched.forms_selected &&
                      formik.errors.forms_selected
                    }
                    onChange={(newValue) => formik.setFieldValue('forms_selected', newValue)}
                  />
                </>
              )}

              <AppText
                id='sqlId'
                name='sqlId'
                label='SQL ID: Ej. D_LEV, T_STRC'
                value={formik.values.sqlId}
                errors={formik.touched.sqlId && !!formik.errors.sqlId}
                errorMessage={formik.touched.sqlId && formik.errors.sqlId}
                onChange={formik.handleChange}
                helperText='D: Desarrollo T: Pruebas'
              />

              <AppText
                id='initialUserEmail'
                name='initialUserEmail'
                label='Usuario inicial'
                value={formik.values.initialUserEmail}
                errors={
                  formik.touched.initialUserEmail &&
                  !!formik.errors.initialUserEmail
                }
                errorMessage={
                  formik.touched.initialUserEmail &&
                  formik.errors.initialUserEmail
                }
                onChange={formik.handleChange}
              />

              <AppSelect
                id='initialUserProfile'
                name='initialUserProfile'
                label='Perfil usuario inicial'
                options={
                  enableExistingAccount
                    ? accountProfiles
                    : formatProfiles(formik.values.template, profiles)
                }
                value={formik.values.initialUserProfile}
                errors={
                  formik.touched.initialUserProfile &&
                  !!formik.errors.initialUserProfile
                }
                errorMessage={
                  formik.touched.initialUserProfile &&
                  formik.errors.initialUserProfile
                }
                onChange={formik.handleChange}
              />

              <AppButtonGroup
                variant='contained'
                aria-label='outlined primary button group'
              >
                <AppButton
                  className="group_button"
                  variant='contained'
                  tipo={BUTTON_TYPES.DEFAULT}
                  onClick={handleClose}
                >
                  Salir
                </AppButton>
                <AppButton
                  className="group_button"
                  variant='contained'
                  tipo='modal-amarillos'
                  type='submit'
                  loading={loading}
                >
                  Guardar
                </AppButton>
              </AppButtonGroup>
            </Form>
          )}
        </Formik>
      </AppPopup>
    </section>
  );
}

export default PopupNewAccount;
