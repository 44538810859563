import React, { useEffect, useState } from 'react';
import { useFirebase } from '../../core/context/firebase-context';
import './index.scss';
import AppPopup from '../../core/components/Popup/AppPopup';
import AppNewPhoto from '../../core/components/NewPhoto/AppNewPhoto';
import AppLoading from '../../core/components/Loading/AppLoading';
import AppErrorMessage from '../../core/components/ErrorMessage/AppErrorMessage';
import AppPagination from '../../core/components/Pagination/AppPagination';
import AppButton from '../../core/components/Button/AppButton';
import { paginate } from '../../core/components/Util/pagination';
import { dataFilteredFunction } from '../../core/components/Util/filter';
import AppSearch from '../../core/components/Search/AppSearch';
import { Formik, Field, Form } from 'formik';
import saveCsv from 'save-csv';

//material
import Button from '@mui/material/Button';
import CardsImages from '../../core/components/CardsImages/CardsImages';

function Images() {
  const firebase = useFirebase();
  const { create } = firebase.user.permissions.images;
  const [isLoading, setLoading] = React.useState(true);
  const [refresh, setRefresh] = React.useState(false);
  const [data, setData] = React.useState([]); //Toda la informacion de storage

  //tags options
  const [tags, setTags] = React.useState([]);
  const [tempTags, setTempTags] = React.useState();

  //foto
  const [imgPhoto, setImgPhoto] = React.useState('');
  const [nameImg, setNameImg] = React.useState('');

  //popupinfoimagen
  const [confirmdata, setConfirmData] = React.useState('');

  const [popupInfoImage, setPopupInfoImage] = React.useState(false);
  const [selectImage, setSelectImage] = React.useState('');

  //popup subir foto
  const [popupUpdatePhoto, setPopupUpdatePhoto] = React.useState(false);
  const [message, setMessage] = useState('');
  const [confirm, setConfirm] = useState(false);

  // editar
  const [edit, setEdit] = React.useState(false);

  //filtro
  const [categorySearched, setCategorySearched] = React.useState('');

  //paginacion
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  React.useEffect(() => {
    let timeoutId;
  
    const fetchData = async () => {
      const title = document.getElementById('title');
      if (title) {
        title.innerHTML = 'Imagenes';
      }
  
      try {
        const dataStorage = await firebase.getImg();
        setData(dataStorage);
      } catch (error) {
        console.error('Error fetching images:', error);
      } finally {
        timeoutId = setTimeout(() => {
          setLoading(false);
        }, 2500);
      }
    };
  
    fetchData();
  
    return () => clearTimeout(timeoutId);
  }, [refresh]);

  React.useEffect(() => {
    const getTags = async () => {
      try {
        await firebase.allTags().then((result) => {
          setTags(result);
        }).catch((error) => {
          console.error(error);
        });
      } catch (error) {
        console.error('Error fetching tags:', error);
      }
    };
    getTags();
  }, []);

  useEffect(() => {
    if (categorySearched.length > 0) {
      setPage(0);
    }
  }, [categorySearched]);

  // if (isLoading) return <AppLoading timmer={0} text={'Cargando fotos'} />;

  const downloadCSV = async () => {
   const databaseIcons = await firebase.getStorageIcons();

   saveCsv(databaseIcons, {
    filename: 'Etiquetas.csv',
  });
  };

  const toggleInfo = (el) => {
    
    if (tempTags) {
      alert('No guardo los cambios');
      setTempTags();
    }
    setSelectImage(el);
    setPopupInfoImage(!popupInfoImage);
  };

  const existImg = (options, value) => {
    return options ? options.find((option) => option.name === value) : null;
  };

  const toggleUpdate = () => {
    setPopupUpdatePhoto(!popupUpdatePhoto);
  };

  const toggleConfirm = () => {
    setConfirm(!confirm);
  };

  const habdleTemporalChange = (values) => {
      let newTags = [];
    if (values && values.tag.length === 0) {
      alert('Selecciona una Etiqueta');
      return;
    }
    else if (values && values.tag.length > 0) {
      values.tag.forEach(element => 
        {tags.forEach(tag => { (tag.nombre === element) ? newTags.push(element) : null; });
      });
    }
    if (newTags.length === 0) alert('No se puede agregar una etiqueta que no existe');
    else { 
      setTempTags({ tag: newTags });
      setEdit(false);
    }
  };

  // Verifica los datos al subir fotos
  const handleVerificationUpdate = async (values) => {
    if (!imgPhoto) {
      setMessage('Selecciona un archivo');
      return;
    }

    if (values.tag.length === 0) {
      setMessage('Selecciona una Etiqueta');
      return;
    }

    let infoIconos = {
      etiqueta: values.tag,
      nombre: nameImg,
      url: imgPhoto,
    };

    try {
      if (tempTags) {
        await updateImgStorage(infoIconos);
        setTempTags();
        setPopupInfoImage(!popupInfoImage);
        updateLocalImage(infoIconos);
      } else {
        const exist = existImg(data, nameImg);
        if (exist) {
          setConfirmData(infoIconos);
          toggleConfirm();
          return;
        }
        await updateStorage(infoIconos);
        updateLocalImage(infoIconos);
      }
      window.location.reload();
    } catch (error) {
      console.error("Error updating the image:", error);
      setMessage("Hubo un error al actualizar la imagen.");
    }
  };

  const updateLocalImage = (updatedImage) => {
    setData((prevImages) =>
      prevImages.map((image) =>
        image.name === updatedImage.nombre
          ? { ...image, tags: updatedImage.etiqueta.toString(), url: updatedImage.url }
          : image
      )
    );
  };

  const updateImgStorage = async (infoIconos) => {
    await firebase.editImg(infoIconos);
  };

  //manda la info a firebase.
  const updateStorage = async (infoIconos) => {
    await firebase.saveImg(infoIconos);
    setRefresh(false);
    setConfirm(false);
    setPopupUpdatePhoto(false);
  };

  //Convertir bytes
  const sizeBytes = (size) => {
    if (size > 1000 && size < 1000000) {
      const info = size / 1000;
      return info + ' KB';
    }
    if (size < 1000000) {
      const info = size / 1000000;
      return info + ' MB';
    } else {
      return size + ' B';
    }
  };

  //filtro
  const dataFiltered = dataFilteredFunction(data, categorySearched, 'name');
const appliedFilter = categorySearched.length ? dataFiltered : data;

// Mapeo para obtener los resultados
const results = Object.keys(appliedFilter).map((el) => {
  const data = { ...appliedFilter[el] };
  return data;
});

// Filtrar los resultados para que no contengan 'folder' en la categoría
const filteredResults = results.filter(item => item.category !== 'folder');

// Paginación aplicada después del filtro
const rows = paginate(filteredResults, page, rowsPerPage).map((item) => item);

  return (
    <section className='images'>
      <div className='container_buttons-principal'>
      <div className='buttons-principal'>
          {
            create && 
            <div className='container-icon' onClick={toggleUpdate}>
              <i className='mdi mdi-folder-upload'></i>Subir foto
            </div>
          }
          <div className='container-icon' onClick={downloadCSV}>
            <i className='mdi mdi mdi-download'></i>Exportar CSV
          </div>
        </div>
      </div>
      <div className='container_header'>
        <AppSearch
          categorySearched={categorySearched}
          setCategorySearched={setCategorySearched}
        />
      </div>
      <CardsImages infoData={rows} onClickCard={toggleInfo} />
      <AppPagination
        results={results}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />

      {/* Informacion de la imagen seleccionada */}
      <AppPopup title='Informacion' onClose={toggleInfo} open={popupInfoImage}>   
        <h3>{selectImage.name}</h3>
        <img src={selectImage.img} alt={selectImage.img} />
        <p> Etiquetas: {(tempTags)? tempTags.tag.toString() : selectImage.tags}</p>
        <p>Tamaño : {sizeBytes(selectImage.size)}</p>
        <p>Tipo : {selectImage.type}</p>
        <p> Fecha creación : {selectImage.created}</p>
        <p>Fecha modificación : {selectImage.updated}</p>
        <div className='btnContainers' style={{gap: (tempTags) ? '2rem' : '0'}}>
          <AppButton
            tipo={'yellow-button'}
            style={{display: (tempTags) ? 'block' : 'none'}}

            onClick={() => handleVerificationUpdate(tempTags)}
          >
            Guardar
          </AppButton>
          <AppButton
          
            tipo={'yellow-button'}
            onClick={() => {
              setEdit(true);
              let imgSelect = {file: {name: selectImage.name, size: selectImage.size, type: selectImage.type}, url: selectImage.img};
              setImgPhoto(imgSelect);
              setNameImg(selectImage.name);
            }}
          >
            Editar
          </AppButton>
        </div>
      </AppPopup>
      {/* EDITAR ETIQUETAS */}
      <AppPopup
      title={'Editar información'}
      open={edit}
      onClose={() => setEdit(false)}
      >
          <Formik
          initialValues={{
            tag: tempTags
              ? tempTags.tag.map((el) => {return  el; })
              : selectImage.tags === '' 
                ? []
                : selectImage.tags?.split(',').map((el) => {return  el; }),
          }}
          onSubmit={habdleTemporalChange}>
          {({ values }) => (
            <Form>
              <div id='checkbox-group'>Etiquetas</div>
              <div
                role='group'
                aria-labelledby='checkbox-group'
                className='options-tags'>
                {tags.map(function (el, index) {
                  return (
                    <label key={el.id}>
                      <Field type='checkbox' name='tag' value={el.nombre} />
                      {el.nombre}
                    </label>
                    );
                  })
                }
              </div>
              <div className='button-confirm-container'>
                <AppButton variant='contained' onClick={()=> {
                  setEdit(false); 
                  setTempTags();
                  }}>
                  Cancelar
                </AppButton>
                <AppButton
                  tipo="yellow-button"
                  variant='contained'
                  className='btn-guardar'
                  type='submit'>
                  Guardar
                </AppButton>
              </div>
            </Form>
          )}
        </Formik>
        
      </AppPopup>

      {/* Subir imagen */}
      <AppPopup title='SubirFoto' onClose={toggleUpdate} open={popupUpdatePhoto}>
        <h3>Subir foto</h3>
        <AppNewPhoto setNameImg={setNameImg} setImgPhoto={setImgPhoto} />
        <Formik
          initialValues={{
            tag: [],
          }}
          onSubmit={handleVerificationUpdate}>
          {({ values }) => (
            <Form>
              <div id='checkbox-group'>Etiquetas</div>
              <div
                role='group'
                aria-labelledby='checkbox-group'
                className='options-tags'>
                {tags.map(function (el, index) {
                  return (
                    <label key={el.id}>
                      <Field type='checkbox' name='tag' value={el.nombre} />
                      {el.nombre}
                    </label>
                  );
                })}
              </div>
              <div className='button-confirm-container'>
                <Button className='btn-cancel' variant='contained' onClick={toggleUpdate}>
                  Cancelar
                </Button>
                <Button
                  variant='contained'
                  className='btn-guardar'
                  type='submit'>
                  Guardar
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <AppErrorMessage message={message} setMessage={setMessage}></AppErrorMessage>
      </AppPopup>

      {/* confirmacion de imagen con el mismo nombre */}
      <AppPopup title='Confirmar reemplazo' onClose={toggleConfirm} open={confirm}>
        <h3>Confirmación</h3>
        <p>Esta imagen ya existe en el storage, ¿Desea reemplazarla?</p>
        <div className='button-confirm-container'>
          <Button variant='contained' onClick={toggleConfirm}>
            Cancelar
          </Button>
          <Button
            variant='contained'
            className='btn-guardar'
            onClick={() => {
              updateStorage(confirmdata);
            }}>
            Aceptar
          </Button>
        </div>
      </AppPopup>
    </section>
  );
}

export default Images;
