import React, { useState } from 'react';

import { Box, Button, CircularProgress, Modal } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import AppButton,{BUTTON_TYPES} from "../../core/components/Button/AppButton";
import './appRestart.scss';
import { useFirebase } from '../../core/context/firebase-context';

export default function AppRestart({
  handleRestore,
  restoring,
  handleToggleModal,
  open,
  error,
}) {

  const { user } = useFirebase();
  const { restore_record } = user.permissions.backup;
  
  return (
    <div className='container_restart'>     
      {restore_record && 
       <AppButton variant="contained" onClick={handleToggleModal} className='buttonRestart'>
          <HistoryIcon className='icon-history' />
          Restaurar versión
        </AppButton>
      }
      <Modal
        open={open}
        onClose={handleToggleModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        className='modal-restart'
      >
        <Box className='modalConfirm'>
          <p className='description'>
            ¿Está seguro de que desea restablecer esta versión del registro?
          </p>

          {error && <p className='error-message'>{error}</p>}
          <div className='containerButtons'>
            <AppButton variant="contained" onClick={handleToggleModal} className='cancelButton'>
              Cancelar
            </AppButton>
            <AppButton tipo={BUTTON_TYPES.YELLOW_BUTTON} variant="contained" className='restoreButton' onClick={handleRestore}>
              {restoring ? (
                <CircularProgress size={24} color='inherit' />
              ) : (
                'Aceptar'
              )}
            </AppButton>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
