import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

//Componentes core
import { useFirebase } from '../../core/context/firebase-context';
import AppPagination from '../../core/components/Pagination/AppPagination';
import { paginate } from '../../core/components/Util/pagination';
import AppSearch from '../../core/components/Search/AppSearch';
import { dataFilteredFunction } from '../../core/components/Util/filter';
import AppSnackbar from '../../core/components/Snackbar/AppSnackbar';

import './styles/index.scss';

//Popups
import PopupNewCompany from './PopupNewCompany';
import AppTableGeneral from '../../core/components/TableGeneral/AppTableGeneral';
import useTablePaginationAndFilter from './hooks/useTablePaginationAndFilter';

/**
 * Component for displaying a list of organizations.
 */

function Organizations() {
  const firebase = useFirebase();
  const navigate = useNavigate();

  const { create } = firebase.user.permissions.organizations;

  const [organizations, setOrganizations] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);

  //Crear organización
  const [popupNewCompany, setPopupNewCompany] = useState(false);

  //Filtro
  const [categorySearched, setCategorySearched] = useState('');

  //Paginación
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  //Snackbar
  const [snackbarProps, setSnackBarProps] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // Filtro por estado del usuario y perfil
  const [accountState, setAccountState] = useState('Todos');
  const [profileFilter, setProfileFilter] = useState('Todos');

  const keysToSearch = ['nombre', 'id', 'estado'];

  const getCompanies = async () => {
    try {
      setLoading(true);
      const result = await firebase.getOrganizations();
      setOrganizations(result);
    } catch (error) {
      console.error('Error fetching organizations:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const title = document.getElementById('title');
    if (title) {
      title.innerHTML = 'Organizaciones';
    }
    getCompanies();
  }, [refresh]);

  /**
   * Updates the organizations state with a new organization.
   * @param {string} idOrg - The ID of the organization to update.
   * @param {object} newOrganization - The new organization data.
   */
  const updateOrganizations = () => {
    setRefresh(prev => !prev); 
  };

  const handleNewCompany = () => {
    setPopupNewCompany(!popupNewCompany);
  };

  if (isLoading) return <div> Loading</div>;

  //filtro
  const dataFiltered = dataFilteredFunction(organizations, categorySearched, 'nombre');

  const appliedFilter = categorySearched.length ? dataFiltered : organizations;

  const results = Object.keys(appliedFilter).map((el) => {
    const data = { ...appliedFilter[el], key: el };
    return data;
  });

  const rows = paginate(results, page, rowsPerPage).map((item) => item);

  const handleSnackBar = (properties) => {
    setSnackBarProps(properties);
    setOpenSnackbar(true);
  };

  const handleAlerts = () => {
    setOpenSnackbar(false);
  };
  
  const organizationsArray = Object.entries(organizations).map(([key, value]) => ({
    key,
    ...value
  }));
  const filterSearch = true;
  const filterSelect = true; 
  const {filteredData: filteredOrganization, totalPages} = useTablePaginationAndFilter(
    organizationsArray,
    categorySearched,
    keysToSearch,
    rowsPerPage,
    accountState,
    profileFilter,
    filterSelect,
    filterSearch
  );

  const columns = [
    { label: 'Nombre', value: 'nombre' },
    { label: 'Identificador', value: 'id' },
    { label: 'Estado', value: 'estado'}
  ];

  return (
    <section className='organizations'>
      <div className='container_buttons-principal'>
        <div className='buttons-principal'>
        {
          create && <div className='container-icon' onClick={handleNewCompany}>
          <i className='mdi mdi-plus-circle-outline'></i>Crear
        </div>
        }
        
        </div>
      </div>
      <div>
        <AppSearch
          categorySearched={categorySearched}
          setCategorySearched={setCategorySearched}
        />
      </div>
      <AppTableGeneral 
        data={filteredOrganization}
        columns={columns}
        page={page}
        rowsPerPage={rowsPerPage}
        onRowClick={(item) => {
          navigate(`/organizations/${item.key}`);
        }}
        possibleEstates={[]}
        possibleProfiles={[]}
        handleAccountFilterChange={() => {}}
        profileFilter={''}
        accountState={''}
      />
      <AppPagination
        results={filteredOrganization}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
      <PopupNewCompany
        open={popupNewCompany}
        handleSnackBar={handleSnackBar}
        handleClose={handleNewCompany}
        setRefresh={setRefresh}
        updateOrganizations={updateOrganizations}
        orgs={organizations}
      />
      <AppSnackbar
        open={openSnackbar}
        onClose={handleAlerts}
        style={snackbarProps.style}
      >
        {snackbarProps.message}
      </AppSnackbar>


    </section>
  );
}

export default Organizations;
