import React from 'react';
import { Tooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/system';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const FullWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    width: '60%',
    maxWidth: '90%',
    padding: 0,
  },
}));



const CustomTooltip = ({ placement = "bottom", title, children }) => {
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltip = (event) => {
    event.stopPropagation(); 
    setOpen(!open);
    };
    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
                <FullWidthTooltip 
                    onClose={handleTooltip} 
                    open={open} 
                    placement={placement}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener 
                    title={title}
                    slotProps={{
                        popper: {
                            disablePortal: true,
                        },
                    }}    
                >
                    <button 
                        type='button' 
                        style={{border: "none", backgroundColor: "transparent"}} 
                        onClick={handleTooltip}
                    > 
                        {children}
                    </button>
                </FullWidthTooltip>
            </div>
        </ClickAwayListener>
    );
};

export default CustomTooltip;
