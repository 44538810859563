import React, { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";

//Formik
import { Formik, Form } from "formik";
import * as Yup from "yup";
import "./index.scss";


//Componentes core
import AppButton from "../../core/components/Button/AppButton";
import AppSnackbar from "../../core/components/Snackbar/AppSnackbar";

//Popups
import PopupNewUser from "./PopupNewUser";
import useUserManager from './hooks/useUserManager';
import { useNavigate } from "react-router-dom";
import { useFirebase } from '../../core/context/firebase-context';
import AppText from "../../core/components/Input/Text/AppText";

/**
 * Main component for managing users.
 *
 * @component
 */

function Users() {

  const navigate = useNavigate();

  const { user } = useFirebase();
  const { create } = user.permissions.users;

  useEffect(() => {
    const title = document.getElementById('title');
    if (title) {
      title.innerHTML = 'Usuarios';
    }
  }, []);

  // Utils to manage users
  const {
    userNotFound,
    openNewUser,
    setNewUser,
    loading,
    setLoading,
    handleNewUser,
    handleAlertUser,
    getAllOrganizations,
    getAccountWithId,
    getUserByEmail
  } = useUserManager();

  //Permite imprimir el error en consola
  const errorHandler = (error, errorInfo) => {
    throw new Error("Loging", error, errorInfo);
  };

  return (

    <section className="search">
      {
        create && (
          <div className="container_buttons-principal">
            <div className='buttons-principal icon-create'>
              <div className='container-icon' onClick={handleNewUser}>
                <i className="mdi mdi-plus-circle-outline" /> Crear
              </div>
            </div>
          </div>
        )
      }      

      <div className='container_search'>
        <Formik
          initialValues={{emailBuscado: ''}}
          validationSchema={Yup.object({
            emailBuscado: Yup.string()
              .email('Correo electrónico inválido')
              .required('Correo electrónico inválido'),
          })}
          onSubmit={async (values, actions) => {
            setNewUser(false);
            setLoading(true);
            await getUserByEmail(values.emailBuscado.toString(), (userData) => {
              navigate(`/users/${userData.uid}`);
            });
          }}
        >
          {(formik, isSubmitting) => (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit(e);
              }}
            >
              <AppText
                id="emailBuscado"
                name="emailBuscado"
                type="email"
                label="Ingresa un email"
                placeholder="email@galapagoagro.co"
                fullWidth
                size="small"
                value={formik.values.emailBuscado}
                onChange={formik.handleChange}
                error={
                  formik.touched.emailBuscado && !!formik.errors.emailBuscado
                }
                helperText={
                  formik.touched.emailBuscado && formik.errors.emailBuscado
                }
                variant="standard"
              />
              <AppButton
                loading={loading}
                type="submit"
                disabled={isSubmitting}
                tipo={'yellow-button'}
                variant='contained'
              >
                Buscar
              </AppButton>
            </Form>
          )}
        </Formik>
      </div>

      <ErrorBoundary onError={errorHandler}>
        <PopupNewUser
          handleClose={handleNewUser}
          popupOpen={openNewUser}
          setOpen={setNewUser}
          getAllOrganizations={getAllOrganizations}
          getAccountWithId={getAccountWithId}
        />
      </ErrorBoundary>

      <AppSnackbar
        open={userNotFound}
        onClose={handleAlertUser}
        style="warning"
      >
        El usuario no ha sido encontrado
      </AppSnackbar>
    </section>
  );
}

export default Users;
