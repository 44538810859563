import React from 'react';
import PropTypes from 'prop-types';

import { CircularProgress } from '@mui/material';

import './index.scss';

function Autocomplete(props) {
  const [options, setOptions] = React.useState([]);
  const [noOptionsOpen, setNoOptionsOpen] = React.useState(false);
  const [dropDownOpen, setDropDownOpen] = React.useState(false);

  const inputRef = React.useRef();

  React.useEffect(() => {
    setOptions(props.data);
  }, [props.data.length]);

  React.useEffect(() => {
    if (props.value) {
      props.onInputChange(props.value.label ? props.value.label : props.value);
      props.onChange(props.value);
    }
  }, [props.value]);

  const handleInputChange = (event) => {
    const { value } = event.target;

    props.onInputChange(value);

    if (props.value && props.value !== value) {
      props.onChange('');
    }

    if (value === '') {
      setNoOptionsOpen(false);

      return setOptions(props.data);
    }

    if (!dropDownOpen) {
      setDropDownOpen(true);
    }

    let results;

    if (props.filter) {
      results = props.data.filter((el) => {
        return el.label.toLowerCase().search(value.toLowerCase()) > -1;
      });
    } else {
      results = props.data;
    }

    const found = results.find((el) => el.label === value);

    if (value.length && !found) {
      setNoOptionsOpen(true);
    } else {
      setNoOptionsOpen(false);
    }

    setOptions(results);
  };

  React.useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  });

  const handleOutsideClick = (e) => {
    if (
      ['mdi mdi-chevron-down', 'mdi mdi-close'].includes(e.target.className)
    ) {
      return;
    }

    if (inputRef.current && !inputRef.current.contains(e.target)) {
      setDropDownOpen(false);

      if (props.onChange) {
        setNoOptionsOpen(false);
      }
    }
  };

  const handleChange = (option) => {
    inputRef.current.focus();

    props.onChange(option);
    props.onInputChange(option.label);

    setDropDownOpen(false);

    if (noOptionsOpen) setNoOptionsOpen(false);
  };

  const handleClick = () => {
    if (props.disabled) return;

    setDropDownOpen(true);

    const found = options.find((el) => el.label === props.value);

    if (props.inputValue.length && !found) {
      setNoOptionsOpen(true);
    } else {
      setNoOptionsOpen(false);
    }

    inputRef.current.focus();
  };

  const clearInput = () => {
    if (props.disabled) return;

    props.onChange('');
    props.onInputChange('');

    setOptions(props.data);

    inputRef.current.focus();

    setDropDownOpen(true);
    setNoOptionsOpen(false);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 && props.onKeyDown) {
      event.preventDefault();

      props.onKeyDown(props.value);
    }
  };

  return (
    <div className="autocomplete">
      <div className="autocomplete-input">
        <input
          name={props.name}
          ref={inputRef}
          value={props.inputValue}
          type="text"
          placeholder={props.placeholder}
          onClick={handleClick}
          onChange={handleInputChange}
          onFocus={props.onFocus}
          onKeyDown={handleKeyDown}
          disabled={props.disabled}
          autoComplete="off"
        />

        {props.loading ? (
          <CircularProgress size={18} />
        ) : (
            <i className="mdi mdi-chevron-down" onClick={handleClick}></i>
          )}

        <div className="autocomplete-dropdown">
          {dropDownOpen && !!options.length && (
            <ul>
              {options.map((option, index) => {
                return (
                  <li key={index} onClick={() => handleChange(option)}>
                    {option.label}
                  </li>
                );
              })}
            </ul>
          )}
       </div>
      </div>

      <i className="mdi mdi-close" onClick={clearInput}></i>
    </div>
  );
}

Autocomplete.propTypes = {
  data: PropTypes.array,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  showNoOptions: PropTypes.bool,
  loading: PropTypes.bool,
  filter: PropTypes.bool
};

Autocomplete.defaultProps = {
  data: [],
  showNoOptions: true,
  inputValue: '',
  filter: true
};

export default Autocomplete;