export const filterTestDatabaseAccounts = (dataIdAccount, testDatabase) => {
    return dataIdAccount.reduce((result, account) => {
      if (account && account?.value?.includes(testDatabase)) {
        result.push({
          value: account.value,
          label: account.label !== undefined ? account.label : '-',
        });
      }
      return result;
    }, []);
  };
  