import React from 'react';
import { useNavigate, useLocation  } from 'react-router-dom';
import ReactJson from 'react-json-view';
import AppButton,{BUTTON_TYPES} from "../../core/components/Button/AppButton";

const AppJsonDetails = ({ selectedItem, onClose, text, showSeeMore, returnPage }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleCancel = () => {
    if (returnPage) {
      returnPage();
    } else {
      onClose();
    }
  };

  const handleSeeMore = () => {
    navigate(`${location.pathname}/${selectedItem.id}`);
  };

  return (
    <div className='jsonDetails modal contentContainer'>
      <div>
        <ReactJson
          src={JSON.parse(selectedItem.registro)}
          displayDataTypes={false}
          enableClipboard={false}
          displayObjectSize={false}
          collapsed={1}
        />
      </div>
      <div className='buttons-json' >
        {showSeeMore && (
          <>
            <AppButton  tipo={BUTTON_TYPES.DEFAULT} variant="contained" onClick={handleCancel} className='buttonCancel'>{text}</AppButton>
            <AppButton tipo={BUTTON_TYPES.YELLOW_BUTTON} variant="contained" onClick={handleSeeMore} className='buttonMore'> Más</AppButton>
          </>
        )}
      </div>
    </div>
  );
};

export default AppJsonDetails;
