import React, {useState} from 'react';
import {useFirebase} from '../../core/context/firebase-context';

import {Form, Formik} from 'formik';
import * as Yup from 'yup';

import AppPassword from '../../core/components/Input/Password/AppPasword';

//Componentes core
import AppPopup from '../../core/components/Popup/AppPopup';
import AppButton,{BUTTON_TYPES} from '../../core/components/Button/AppButton';
import AppButtonGroup from '../../core/components/ButtonGroup/AppButtonGroup';
import PopoupPasswordChanged from './PopupPasswordChanged';
import AppSnackbar from '../../core/components/Snackbar/AppSnackbar';

import './PopupRecoverPassword.scss';

// eslint-disable-next-line @typescript-eslint/no-require-imports
const dateUtils = require('../../core/util/dateFns');


/**
 * Represents a popup for recovering and resetting a user's password.
 * @param {Object} props - The component's props.
 * @param {Function} props.handleClose - A function to close the popup.
 * @param {boolean} props.open - A boolean indicating whether the popup is open.
 * @param {Object} props.user - An object containing user data.
 */

export const PopoupRecoverPassword = ({handleClose, open, user}) => {
  const firebase = useFirebase();

  const [popupOpenPasswordChanged, setPopupOpenPasswordChanged] =
    useState(false);

  const [password, setPassword] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handlePopupPasswordChanged = (password) => {
    setPassword(password);
    setPopupOpenPasswordChanged(!popupOpenPasswordChanged);
  };

  const handleAlert = () => {
    setOpenAlert(!openAlert);
  };

  const sendEmailResetPassword = async (infoUser) => {
    await firebase.sendEmailResetPassword(infoUser).catch(function (error) {
      console.error(error);
    });
  };

  const handleOnSubmit = async (values, actions) => {
    const {newPassword} = values;

    const todayDate = dateUtils.get_current_datetime_DMY();

    setIsLoading(true);

    const dataUser = {
      email: user.correo,
      name: user.nombre,
      uid: user.uid,
      password: newPassword,
      fecha: todayDate,
    };

    await firebase
      .updateUser(dataUser)
      .then(function (result) {
        sendEmailResetPassword(dataUser);
        handleClose();
        actions.resetForm();
        handlePopupPasswordChanged(values.newPassword);
        setIsLoading(false);           
      })
      .catch(function (error) {
        console.error(error);
        handleAlert();
        setIsLoading(false);
      });
    
  };

  return (
    <div className='modal'>
      <Formik
        initialValues={{
          newPassword: '',
          confirmPassword: '',
        }}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          newPassword: Yup.string().required(
            'Campo requerido'
          ).min(6, 'La contraseña es muy corta - debe ser mínimo de 6 caracteres.').matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
            "La contraseña debe contener minúsucula, mayúscula y un número"
          ),
          confirmPassword: Yup.string()
            .oneOf(
              [Yup.ref('newPassword'), null],
              'Las contraseñas no coinciden'
            )
            .required('Campo requerido'),
        })}
        onSubmit={(values, actions) => {
          handleOnSubmit(values, actions);
        }}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <AppPopup
              title='Restablecer contraseña'
              onClose={() => handleClose()}
              open={open}
            >
              <AppPassword
                name='newPassword'
                label='Nueva contraseña'
                autoComplete='off'
                disabled={false}
                value={formik.values.newPassword}
                errors={
                  formik.touched.newPassword && !!formik.errors.newPassword
                }
                errorMessage={
                  formik.touched.newPassword && formik.errors.newPassword
                }
                onChange={formik.handleChange}
              />
              <AppPassword
                name='confirmPassword'
                label='Confirmar contraseña'
                autoComplete='off'
                disabled={false}
                value={formik.values.confirmPassword}
                errors={
                  formik.touched.confirmPassword &&
                  !!formik.errors.confirmPassword
                }
                errorMessage={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
                onChange={formik.handleChange}
              />

              <div className='notes'>
                <span className='note-password'>
                  La contraseña debe ser mínimo de 6 caracteres y contener minúsucula, mayúscula y un número.
                </span>
              </div>

              <AppButtonGroup
                variant='contained'
                aria-label='outlined primary button group'
              >
                <AppButton
                  variant='contained'
                  tipo={BUTTON_TYPES.DEFAULT}
                  onClick={() => {
                    formik.resetForm();
                    handleClose();
                    setIsLoading(false);
                  }}
                >
                  Cancelar
                </AppButton>
                <AppButton
                  variant='contained'
                  tipo='modal-amarillos'
                  type='submit'
                  onClick={formik.handleSubmit}
                  loading= {isLoading}
                >
                  Aceptar
                </AppButton>
              </AppButtonGroup>
            </AppPopup>
          </Form>
        )}
      </Formik>
      <PopoupPasswordChanged
        open={popupOpenPasswordChanged}
        handleClose={handlePopupPasswordChanged}
        email={user.correo}
        password={password}
      />
      <AppSnackbar
        open={openAlert}
        onClose={() => handleAlert()}
        style='warning'
      >
        Error al cambiar la contraseña del usuario
      </AppSnackbar>
    </div>
  );
};
