
import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import AppButton from "../../core/components/Button/AppButton";


function AppListMetadata({ data }) {

    const navigate = useNavigate();
    const returnPage = () => {
        navigate(-1);
    };
    return (
        <div className='container-metadata'>
            <ul className='list-metadata'>
                {data.map(({ label, value }) => (
                    <li key={label} className='li-metadata'>
                        <strong>{label}: </strong>{value}
                    </li>
                ))}
            </ul>
            <AppButton variant="contained" className='button_return' onClick={returnPage}>Regresar</AppButton>
        </div>
    );
}
export default AppListMetadata;