import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useLocation, useParams} from 'react-router-dom';
import * as Yup from 'yup';
// Core
import Loading from '../../../core/components/Loading/AppLoading.js';
import AppButton,{BUTTON_TYPES} from '../../../core/components/Button/AppButton.js';
import AppButtonGroup from '../../../core/components/ButtonGroup/AppButtonGroup.js';
import AppTableGeneral from '../../../core/components/TableGeneral/AppTableGeneral.js';
import AppPagination from '../../../core/components/Pagination/AppPagination.js';
import AppText from '../../../core/components/Input/Text/AppText.js';
import AppSnackbar from '../../../core/components/Snackbar/AppSnackbar.js';
import AppSearch from '../../../core/components/Search/AppSearch.js';
import ExportFile from './ExportFile.js';
import InputLabel from '@mui/material/InputLabel';
import useTablePaginationAndFilter from '../hooks/useTablePaginationAndFilter.js';

import {useFirebase} from '../../../core/context/firebase-context.js';

//Formik
import {Formik, Form} from 'formik';
import { useAxiosInstance } from '../../../api/axiosInstance.js';
import { ENDPOINTS } from '../../../api/endpoints.js';
import { useToast } from '../../../core/context/toast.js';
import { getInitialAccountValues } from './utils/initalAccountValues.js';
import SelectField from '../../../core/components/Input/Select/AppSelect.js';
import SwitchField from '../../../core/components/Input/Switch/AppSwitch.js';

const AccountDetails = () => {
  const firebase = useFirebase();
  const navigate = useNavigate();
  const {accountId, orgId} = useParams();

  const {state} = useLocation();
  const {orgs, dbId} = !!state && state;
  const [accountData, setAccountData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  //Cuenta maestra
  const [editMode, setEditMode] = useState(false);
  const [optionsMasters, setOptionsMasters] = useState([]);

  const toast = useToast();
  const axiosInstance = useAxiosInstance();

  // Popup error - cuenta sin datos
  const [accountNotFound, setaccountNotFound] = useState(false);

  const accountNotFoundRef = useRef(false);
  const [errorAlert, setErrorAlert] = useState(null);

  //Paginación
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Filtro por estado del usuario y nombre
  const [accountState, setAccountState] = useState('Todos');
  const [categorySearched, setCategorySearched] = useState('');

  // Definir cols a filtrar
  const keysToSearch = ['nombre', 'correo', 'estado', 'perfil'];

  // Filtro por perfil
  const [profileFilter, setProfileFilter] = useState('Todos');

  const initialAccountValue = {
    name: accountData && accountData.nombre ? accountData.nombre : '',
    organizacion:
      accountData && accountData.organizacion ? accountData.organizacion : '',
  };

  const userData = Object.values(
    accountData && accountData.usuarios ? accountData.usuarios : ''
  );

  // Data de usuarios de la cuenta
  const dataUsers = Object.values(userData).map(({nombre, correo, perfil}) => ({
    nombre,
    correo,
    perfil,
    estado: perfil === 'inactivo' ? 'inactivo' : 'activo',
  }));

  const dataUsersAccount = dataUsers.map((user) => {
    const {perfil, estado} = user;

    if (estado === 'inactivo') {
      return {
        ...user,
        estado: 'inactivo',
      };
    }
    // Verificar si el perfil existe en accountData.perfiles
    if (perfil in accountData.perfiles) {
      // Obtener el nombre correspondiente
      const {nombre} = accountData.perfiles[perfil];
      return {
        ...user,
        perfil: nombre,
      };
    }

    // Si el perfil no existe en accountData.perfiles, conservar solo los datos originales
    return user;
  });

  // Data para exportar en formato xlsx y csv
  const dataFile = dataUsersAccount.map((user) => ({
    ...user,
    ...initialAccountValue,
    cuenta: initialAccountValue.name,
  }));

  for (const user of dataFile) {
    delete user.name;
  }
  const handleAlertAccount = () => {
    setaccountNotFound(!accountNotFound);
    accountNotFoundRef.current = !accountNotFoundRef.current;
  };

  // Crea un array de perfiles unicos a partir de los usuarios
  const possibleProfiles = [
    ...new Set(dataUsersAccount.map((user) => user.perfil)),
  ];

  // Crea un array de estados unicos a partir de los usuarios
  const possibleEstates = [
    ...new Set(dataUsersAccount.map((user) => user.estado)),
  ];

  // Obtener la data de la cuenta seleccionada por el id
  useEffect(() => {
    let isMounted = true;
    const title = document.getElementById('title');
    title ? (title.innerHTML = 'Información de la cuenta') : null;
    const fetchAccount = async () => {
      try {
        setIsLoading(true);
        const data = await getAccountWithId(accountId);
        const accountsData = await firebase.getOrganizationById({id: orgId});
        const [isMaster, slaveAccount, optionsMasterAccounts] = getInitialAccountValues(accountsData.cuentas, accountId);
        if (isMounted) {
          setOptionsMasters(optionsMasterAccounts);
          setAccountData({ masterAccount: isMaster, slaveAccount: slaveAccount, ...data});
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        setaccountNotFound(true);
        accountNotFoundRef.current = true;
      }
    };
    fetchAccount();
    return () => {
      isMounted = false;
    };
  }, [accountId, orgId]);

  const handleReturnPage = () => {
    const urlAccount = `/organizations/${orgId}`;
    navigate(urlAccount, {
      replace: true,
      state: {orgs}
    });
  };

  // Consulta una cuenta por id
  const getAccountWithId = async (accountID) => {
    try {
      if (accountID) {
        const result = await firebase.getAccountById({accId: accountID});
        if (result && result.data && result.data.id) {
          return result.data.id;
        } else {
          setErrorAlert(
            'No se pudo obtener la cuenta. Inténtalo de nuevo más tarde.'
          );
          setaccountNotFound(true);
        }
      } else {
        setErrorAlert('El ID de la cuenta no está definido.');
        setaccountNotFound(true);
      }
    } catch (error) {
      setErrorAlert('Error al obtener la cuenta.');
      setaccountNotFound(true);
    }
  };

  const handleSubmit = async (values) => {
    try {
      const body = {
        masterAccountId: values.masterAccount ? accountId : values.slaveAccount,
      };
      await axiosInstance.put(
        ENDPOINTS.ORGANIZATION.MASTER_ACCOUNT(accountData.organizacion_id, accountId),
        body
      );
      toast.show(
        "Cuenta actualizada con éxito", {
          id: "success-account",
          type: "success"
        }
      );
      setEditMode(false);
    } catch (error) {
      toast.show(
        "Hubo un error, por favor intentelo de nuevo", {
          id: "error-account",
          type: "error"
        }
      );
    }
  };

  const filterSearch = true; // Filtro search
  const filterSelect = true; // Filtro select

  // Funcion de paginacion y filtrado para obtener datos filtrados y total de pages
  const {filteredData: filteredDataUsers, totalPages} =
    useTablePaginationAndFilter(
      dataUsersAccount,
      categorySearched,
      keysToSearch,
      rowsPerPage,
      accountState,
      profileFilter,
      filterSearch,
      filterSelect
    );

  const handleAccountFilterChange = (e) => {
    if (e.target.name === 'estado') {
      setAccountState(e.target.value);
    } else if (e.target.name === 'perfil') {
      setProfileFilter(e.target.value);
    }
    setPage(0);
  };

  if (page >= totalPages) {
    setPage(totalPages - 1);
  }

  if (isLoading) {
    return <Loading />;
  }

  if (accountNotFound) {
    return (
      <div>
        <AppSnackbar
          open={accountNotFound}
          onClose={handleAlertAccount}
          style='warning'
          message={errorAlert}
        />
      </div>
    );
  }

  const columns = [
    { label: 'Nombre', value: 'nombre' },
    { label: 'Correo', value: 'correo' },
    { label: 'Perfil', value: 'perfil' },
    { label: 'Estado', value: 'estado' }
  ];

  const validationSchema = Yup.object().shape({
    masterAccount: Yup.boolean(),
    slaveAccount: Yup.string().when('masterAccount', {
      is: false,
      then: Yup.string().required('El campo "Esclava de" es obligatorio'),
    }),
  });
  
  return (
    <div className='form_container'>
      <section className='organizations'>
        <Formik 
          initialValues={{
            masterAccount: accountData ? accountData.masterAccount : false,
            slaveAccount: accountData ? accountData.slaveAccount : '',
            name: accountData ? accountData.nombre : '',
            organizacion: accountData ? accountData.organizacion : ''
          }} 
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({values, setFieldValue, isSubmitting, touched, errors}) => (
            <Form>
              <div className='container-forms'>
                <AppText
                  id='name'
                  name='name'
                  type='text'
                  label='Nombre'
                  disabled
                  fullWidth
                  value={values.name}
                />
                <AppText
                  id='organizacion'
                  name='organizacion'
                  type='text'
                  label='Organización'
                  disabled
                  fullWidth
                  value={values.organizacion}
                />
              </div>
              <div className='form_master_account'>
                <div className='form_check_master'>
                  <SwitchField
                    name="masterAccount"
                    disabled={!editMode}
                    value={values.masterAccount}
                    onChange={(checked) => setFieldValue("masterAccount", checked)}
                    label="Cuenta Maestra"
                    errors={
                      touched.masterAccount &&
                      !!errors.masterAccount
                    }
                    errorMessage={
                      touched.masterAccount &&
                      errors.masterAccount
                    }
                  />
                </div>
                {!values.masterAccount && (
                  <div className='form_slave_account'>
                    <InputLabel id="demo-simple-select-label">Esclava de</InputLabel>
                    <SelectField
                      id="slaveAccount"
                      name="slaveAccount"
                      options={optionsMasters}
                      value={values.slaveAccount}
                      disabled={!editMode || optionsMasters.length === 0}
                      onChange={(event) => setFieldValue("slaveAccount", event.target.value)}
                      errors={
                        touched.slaveAccount &&
                        !!errors.slaveAccount
                      }
                      errorMessage={
                        touched.slaveAccount &&
                        errors.slaveAccount
                      }
                    />
                  </div>
                )}
              </div>
              <div className='container-filter'>
                <div className='container-header'>
                  <AppSearch
                    categorySearched={categorySearched}
                    setCategorySearched={setCategorySearched}
                  />
                </div>
                <div className='container-filter'>
                  <ExportFile data={dataFile} fileName='dataUsuarios' />
                </div>
              </div>
              <AppTableGeneral
                accountState={accountState}
                profileFilter={profileFilter}
                handleAccountFilterChange={handleAccountFilterChange}
                possibleEstates={possibleEstates}
                possibleProfiles={possibleProfiles}
                dataUsers={dataUsers}
                title='Usuarios'
                data={filteredDataUsers}
                columns={columns}
                disabled={false}
                page={page}
                rowsPerPage={rowsPerPage}
                onRowClick={() => {}}
              />
              <AppPagination
                results={filteredDataUsers}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
              />
              <AppButtonGroup
                variant='contained'
                aria-label='outlined primary button group'
              >
                <AppButton
                  onClick={() => {
                    handleReturnPage();
                  }}
                  variant='contained'
                  tipo={BUTTON_TYPES.DEFAULT}
                  id='fixedbutton'
                  className="group_button"
                >
                  Regresar
                </AppButton>
                {editMode && (
                    <AppButton
                      type="submit"
                      variant="contained"
                      tipo="yellow-button"
                      loading={isSubmitting}
                      className="group_button"
                    >
                      Guardar
                    </AppButton>
                  )}
                  {!editMode && (
                    <AppButton
                      onClick={() => setEditMode(true)}
                      variant="contained"
                      tipo="yellow-button"
                      className="group_button"
                    >
                      Editar
                    </AppButton>
                )}
              </AppButtonGroup>
            </Form>
          )}
        </Formik>
      </section>
    </div>
  );
};

export default AccountDetails;
