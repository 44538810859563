import React, {useState} from 'react';
import {Formik, Form} from 'formik';
import {validationSchema} from './validations';
import {filterTestDatabaseAccounts} from '../utils/helpers';

import AppPopup from '../../../core/components/Popup/AppPopup.js';
import AppSelect from '../../../core/components/Input/Select/AppSelect.js';
import AppButton,{BUTTON_TYPES} from '../../../core/components/Button/AppButton.js';
import AppSwitch from '../../../core/components/Input/Switch/AppSwitch.js';
import AppMultiListField from '../../../core/components/Input/Text/AppMultiListField.js';
import AppSelectMultiple from '../../../core/components/Input/Select/AppSelectMultiple.js';

import useFetchAccountData from '../hooks/useFetchAccountData';
import {useAxiosInstance} from '../../../api/axiosInstance.js';
import {ENDPOINTS} from '../../../api/endpoints.js';


const PopupPublicRecords = ({
  handleClose,
  open,
  organizations,
  dbId,
  dataIdAccount,
  handleSnackBar,
}) => {
  const axiosInstance = useAxiosInstance();
  const [availableAccountsDestination, setAvailableAccountsDestination] =
    useState(dataIdAccount);

  const [isLoading, setIsLoading] = useState(false);
  const testDatabase = process.env.REACT_APP_DB_TEST;
  const accountOrigin = filterTestDatabaseAccounts(dataIdAccount, testDatabase);

  const {availableForms, getForms} = useFetchAccountData(
    organizations && organizations[dbId]
  );

  const initialValues = {
    accountOrigin: '',
    accountsDestination: [],
    selectForms: [],
    enableRecordsEmail: false,
    email: [],
  };

  const handleFormSubmit = async (values, actions) => {
    const {selectForms, enableRecordsEmail, email, accountsDestination, accountOrigin} = values;

      const body = {
        accountOriginId: accountOrigin,
        accountsDestination: accountsDestination.map(account=> account.id),
        formsToPublish: selectForms.map((form) => form.value),
        ignoreGalappEmail: enableRecordsEmail,
        ...(email.length > 0 && { exceptionEmail: email }),
      };

      if(email.length > 0){
        body['exceptionEmail'] = email;
      }

    try {
      const response = await axiosInstance.post(ENDPOINTS.DATA.REPLICATE_RECORDS, body);
      if(response.status === 200) {
        handleSnackBar({
          message: 'Se han publicado los registros con éxito',
          style: 'success',
        });
      }
      setIsLoading(false);
      handleClose();
    } catch (error) {
      handleSnackBar({message: error, style: 'error'});
    }
  };

  return (
    <AppPopup title='Publicar registros' onClose={handleClose} open={open}>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema(testDatabase)}
        onSubmit={handleFormSubmit}
      >
        {(formik, isSubmitting) => (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit(e);
            }}
          >
            <>
              <div className='container-selec'>
                <AppSelect
                  id='accountOrigin'
                  name='accountOrigin'
                  label='Cuenta origen'
                  options={accountOrigin}
                  value={formik.values.accountOrigin}
                  errors={
                    formik.touched.accountOrigin &&
                    !!formik.errors.accountOrigin
                  }
                  errorMessage={
                    formik.touched.accountOrigin && formik.errors.accountOrigin
                  }
                  onChange={async (newValue) => {
                    const {value} = newValue.target;

                    formik.setFieldValue('accountOrigin', value);

                    const filteredAccounts = dataIdAccount.filter(
                      (account) => !value || account.value !== value
                    );
                    setAvailableAccountsDestination(filteredAccounts);
                    await getForms(value);
                  }}
                  disabled={!!formik.values.accountsDestination?.length}
                />
              </div>

              <div className='container-selec'>
                <AppSelectMultiple
                  id='accountsDestination'
                  name='accountsDestination'
                  options={availableAccountsDestination}
                  label='Cuentas destino'
                  value={formik.values.accountsDestination}
                  errors={
                    formik.touched.accountsDestination &&
                    !!formik.errors.accountsDestination
                  }
                  errorMessage={
                    formik.touched.accountsDestination &&
                    formik.errors.accountsDestination
                  }
                  onChange={(e) => {
                    formik.setFieldValue('accountsDestination', e);
                  }}
                />
              </div>
              <div className='container-selec-for'>
                <AppSelectMultiple
                  id='selectForms'
                  name='selectForms'
                  options={availableForms.filter(
                    (form) => form.type != 'anidado'
                  )}
                  label='Formularios'
                  value={formik.values.selectForms}
                  errors={
                    formik.touched.selectForms && !!formik.errors.selectForms
                  }
                  errorMessage={
                    formik.touched.selectForms && formik.errors.selectForms
                  }
                  onChange={(e) => {
                    formik.setFieldValue('selectForms', e);
                  }}
                />
              </div>
              <div className='container-switch '>
                <AppSwitch
                  name={'enableRecordsEmail'}
                  label={'Omitir @galapagoagro.co'}
                  labelLeft={'No'}
                  labelRight={'Si'}
                  className={'switch-button'}
                  checked={formik.values.enableRecordsEmail}
                  value={formik.values.enableRecordsEmail}
                  onChange={(e) => {
                    formik.setFieldValue('enableRecordsEmail', e);
                  }}
                />
              </div>
              <div className='container-inp'>
                <AppMultiListField
                  id='email'
                  name='email'
                  label='Correos excepción (Opcional) :'
                  variant='outlined'
                  disabled={formik.values.enableRecordsEmail === false}
                  value={formik.values.email}
                  errors={formik.touched.email && !!formik.errors.email}
                  errorMessage={formik.touched.email && formik.errors.email}
                  onChange={(value) => {
                    formik.setFieldValue('email', value);
                  }}
                />
              </div>

              <div className='container-btns'>
                <div className='container-headers'>
                  <AppButton
                    variant='contained'
                    tipo={BUTTON_TYPES.DEFAULT}
                    onClick={handleClose}
                  >
                    Cancelar
                  </AppButton>
                </div>
                <div className='container-headers'>
                  <AppButton
                    variant='contained'
                    disabled={isSubmitting}
                    tipo='modal-amarillos'
                    type='submit'
                    loading={isLoading}
                  >
                    Aceptar
                  </AppButton>
                </div>
              </div>
            </>
          </Form>
        )}
      </Formik>
    </AppPopup>
  );
};

export default PopupPublicRecords;
