import React from 'react';
import {
  Route,
  Routes as Switch,
  Navigate,
} from 'react-router-dom';

import { useFirebase } from './core/context/firebase-context';


import Login from './pages/Login/index.js';
import Dashboard from './pages/Dashboard/index.js';

const Routes = () => {
  const firebase = useFirebase();

  if (!firebase.user) {
    return (
      <Switch>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route path="*" element={<Dashboard />} />
    </Switch>
  );
};

export default Routes;
