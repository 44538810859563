import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormError from '../../FormError/AppFormError';

import './AppPassword.scss';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

function AppPassword({
  id,
  name,
  label,
  value,
  type,
  errors,
  errorMessage,
  onChange,
  disabled,
  ...props
}) {

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  return (
    <>
      <FormControl fullWidth focused>
        <TextField
          label={label}
          id={id}
          name={name}
          fullWidth
          disabled={disabled ? disabled : false}
          error={errors}
          value={value}
          type={showPassword ? "text" : "password"}
          onChange={(e) => onChange(e)}
          autoComplete='off'
          variant="standard"
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...props}
        />
      </FormControl>
      <FormError errorMessage={errorMessage} />
    </>
  );
}

export default AppPassword;
