import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { validationSchema } from './validations';
import { filterTestDatabaseAccounts } from '../utils/helpers';

import AppPopup from '../../../core/components/Popup/AppPopup.js';
import AppSelect from '../../../core/components/Input/Select/AppSelect.js';
import AppButton,{BUTTON_TYPES} from '../../../core/components/Button/AppButton.js';
import AppSelectMultiple from '../../../core/components/Input/Select/AppSelectMultiple.js';

import { useAxiosInstance } from '../../../api/axiosInstance.js';
import { ENDPOINTS } from '../../../api/endpoints.js';
import './PopupPublicForms.scss';
import useFetchAccountData from '../hooks/useFetchAccountData';

const PopupPublicForms = ({
  handleClose,
  open,
  organizations,
  dbId,
  dataIdAccount,
  handleSnackBar,
}) => {
  const axiosInstance = useAxiosInstance();
  const [availableAccountsDestination, setAvailableAccountsDestination] = useState(dataIdAccount);
  const [isLoading, setIsLoading] = useState(false);

  const testDatabase = process.env.REACT_APP_DB_TEST;

  // Filtrar las cuentas de origen
  const accountOrigin = filterTestDatabaseAccounts(dataIdAccount, testDatabase);

  const { availableForms, getForms } = useFetchAccountData(organizations && organizations[dbId]);

  const initialValues = {
    accountOrigin: '',
    accountsDestination: [],
    selectForms: [],
  };

  const handleFormSubmit = async (values, actions) => {
    setIsLoading(true);
    const { selectForms, accountsDestination } = values;
    
    const body = {
      accountOriginId: values.accountOrigin,
      formsToPublish: selectForms.map((form) => form.value),
      accountsDestination: accountsDestination.map((account) => account.id)
    };

    try {
      const response = await axiosInstance.post(ENDPOINTS.FORM.REPLICATE_FORMS, body);

      if(response.status === 200) {
        handleSnackBar({
          message: 'Se han publicado los formularios con éxito',
          style: 'success',
        });
      }
      setIsLoading(false);
      handleClose();

    } catch (error) {
      handleSnackBar({
        message: 'Error al publicar formularios',
        style: 'error',
      });
    }
  };

  return (
    <AppPopup title='Publicar formulario' onClose={handleClose} open={open}>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema()}
        onSubmit={handleFormSubmit}
      >
        {(formik, isSubmitting) => (
          <Form onSubmit={(e) => { e.preventDefault(); formik.handleSubmit(e); }}>
            <>
              <div className='container-selec'>
                <AppSelect
                  id='accountOrigin'
                  name='accountOrigin'
                  label='Cuenta origen'
                  options={accountOrigin}
                  value={formik.values.accountOrigin}
                  errors={formik.touched.accountOrigin && !!formik.errors.accountOrigin}
                  errorMessage={formik.touched.accountOrigin && formik.errors.accountOrigin}
                  onChange={async (newValue) => {
                    const { value } = newValue.target;
                    formik.setFieldValue('accountOrigin', value);
                    const filteredAccounts = dataIdAccount.filter(account => !value || account.value !== value);
                    setAvailableAccountsDestination(filteredAccounts);
                    await getForms(value);
                  }}
                  disabled={!!formik.values.accountsDestination?.length}
                />
              </div>

              <AppSelectMultiple
                id='accountsDestination'
                name='accountsDestination'
                options={availableAccountsDestination}
                label='Cuentas destino'
                value={formik.values.accountsDestination}
                errors={formik.touched.accountsDestination && !!formik.errors.accountsDestination}
                errorMessage={formik.touched.accountsDestination && formik.errors.accountsDestination}
                onChange={(e) => formik.setFieldValue('accountsDestination', e)}
              />

              <AppSelectMultiple
                id='selectForms'
                name='selectForms'
                options={availableForms}
                label='Formularios'
                value={formik.values.selectForms}
                errors={formik.touched.selectForms && !!formik.errors.selectForms}
                errorMessage={formik.touched.selectForms && formik.errors.selectForms}
                onChange={(e) => formik.setFieldValue('selectForms', e)}
              />

              <div className='container-btns'>
                <div className='container-headers'>
                  <AppButton
                    variant='contained'
                    tipo={BUTTON_TYPES.DEFAULT}
                    onClick={handleClose}
                  >
                    Cancelar
                  </AppButton>
                </div>
                <div className='container-headers'>
                  <AppButton
                    variant='contained'
                    disabled={isSubmitting}
                    tipo='modal-amarillos'
                    type='submit'
                    loading={isLoading}
                  >
                    Aceptar
                  </AppButton>
                </div>
              </div>
            </>
          </Form>
        )}
      </Formik>
    </AppPopup>
  );
};

export default PopupPublicForms;
