import React from 'react';
import { FormControl, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';

function Search(props) {
  const handleFilterChange = (e) => {
    let value = e.target.value;
    props.setCategorySearched(value);
  };

  return (
    <div>
      <div >
        <FormControl sx={{width: '35ch' }} size="small" value={props.categorySearched}
          onChange={handleFilterChange}>
          <InputLabel htmlFor="outlined-adornment-amount">Buscar</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            startAdornment={<InputAdornment position="start"> <i className='mdi mdi-magnify'></i></InputAdornment>}
            label="Amount"
          />
        </FormControl>
      </div>
      {props.showError && props.filteredData.length === 0 && (
        <div className='error-message'>
          Introduce un valor válido para la búsqueda.
        </div>
      )}
    </div>
  );
}

export default Search;
