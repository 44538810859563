import React from 'react';

import AppButton,{BUTTON_TYPES} from '../Button/AppButton';
import AppButtonGroup from '../ButtonGroup/AppButtonGroup';

import './AppEndButtons.scss';

export default function AppEndButtons({
  isDisabled,
  shouldShowExtraButton,
  extraButtonLabel,
  handleExtraButton,
  handleEdit,
  handleExit,
  isSubmitting,
  shouldShowEditButton,
}) {
  return (
    <AppButtonGroup>
      <AppButton
        variant='contained'
        className="group_button"
        tipo={BUTTON_TYPES.DEFAULT}
        onClick={isDisabled ? handleExit : handleEdit}
      >
        Salir
      </AppButton>

      {isDisabled && shouldShowEditButton && (
        <AppButton
          className="group_button"
          variant='contained'
          tipo='yellow-button'
          onClick={handleEdit}
        >
          Editar
        </AppButton>
      )}

      {!isDisabled && shouldShowExtraButton && (
          <AppButton
            variant='contained'
            tipo='modal-amarillos'
            className="group_button"
            onClick={handleExtraButton}
          >
            {extraButtonLabel}
          </AppButton>
      )}
      {!isDisabled && (

          <AppButton
            variant='contained'
            type='submit'
            disabled={isSubmitting}
            tipo='modal-amarillos'
            className="group_button"
          >
            Guardar
          </AppButton>
      )}
    </AppButtonGroup>
  );
}