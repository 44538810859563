import React,{useState} from 'react';
import { MenuItem, IconButton, Menu } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import './FilterSelect.scss';

const FilterSelect = (props) => {
  const { name, value, onChange, options, label } = props;
  const [anchorEl, setAnchorEl] = useState(null);

    const handleIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (selectedValue) => {
    setAnchorEl(null);
    onChange({ target: { name, value: selectedValue } });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className='root'>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={handleIconClick}>
          <FilterAltIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => handleMenuItemClick('Todos')}>Todos</MenuItem>
          {options.map((option) => (
            <MenuItem key={option} onClick={() => handleMenuItemClick(option)}>
              {option}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </div>
  );
};

export default FilterSelect;
