import React, { useEffect, useState } from 'react';
import { useFirebase } from '../../core/context/firebase-context';

//Formik
import { Formik, Form} from 'formik';
import * as Yup from "yup";

//Material
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';

//Componentes core
import AppButtonGroup from '../../core/components/ButtonGroup/AppButtonGroup';
import AppPopup from '../../core/components/Popup/AppPopup';
import AppButton,{BUTTON_TYPES} from '../../core/components/Button/AppButton';
import AppSwitch from '../../core/components/Input/Switch/AppSwitch';
import AppFormError from '../../core/components/FormError/AppFormError';
import AppAutocomplete from '../../core/components/Input/Autocomplete';
import { InputLabel } from '@mui/material';


/**
 * Popup for assigning an account.
 * @param {Object} props - The component's props.
 * @param {Function} props.handleClose - A function to close the popup.
 * @param {boolean} props.open - A boolean indicating whether the popup is open.
 * @param {Object} props.organizations - An object containing organization data.
 */

function PopupAssignAccount(props) {

    //Backend
    const firebase = useFirebase();

    //Organizaciones filtradas
    const [filteredOrganizations, setFilteredOrganizations] = useState({});
    const [inputValueOrg, setInputValueOrg] = useState('');
    const [inputValueAcc, setInputValueAcc] = useState('');
    const [inputValuePrf, setInputValuePrf] = useState('');

    //Inicializa el Popup
    useEffect( () =>{
        if(props.organizations){
            filterOrganizations();
        }    
      }, [props]);

    useEffect(()=>{
        if(inputValueOrg === ''){
            setInputValueAcc('');
        }
    },[inputValueOrg]);

    useEffect(()=>{
        if(inputValueAcc === ''){
            setInputValuePrf('');
        }
    },[inputValueAcc]);


    useEffect(()=>{
        if(!props.open){
            setInputValuePrf('');
            setInputValueAcc('');
            setInputValueOrg('');
        }
    },[props.open]);
    
    //Filtra las organizaciones que tengan al menos una cuenta
    const filterOrganizations = ()=>{
       let filtered = [];
         Object.keys(props.organizations).map(key => {
          if(props.organizations[key].cuentas != undefined){
            filtered.push(props.organizations[key]);
          }
        });      
        setFilteredOrganizations(filtered);
      
      };
    
    //Retorna solo las cuentas de la organización seleccionada
    const getAccountsOrganization = organization =>{
        const accounts = [];
      
          return new Promise((resolve, reject) => {
            Object.keys(filteredOrganizations).map((key,index)=>{
              key === organization? accounts.push(filteredOrganizations[key].cuentas): '';
            }); 
      
            resolve(accounts[0]);
          });
      };

    return (
        <div>
             <AppPopup title='Asignar una cuenta' onClose={()=>props.handleClose()} open={props.open} className='header'>
                <Formik
                    initialValues={{
                    organization: '',
                    account:'',
                    accounts:[],
                    profile: '',
                    profiles:[],
                    admin: false,
                    base_datos:'',
                    nodo_raiz:'',
                    accountName:'',
                    defaultAccount:props.firstAccount?true:false,
                    superset_activo:false,
                    superset_url: 'https://analytics.galapp.co/'
                    }}
                    enableReinitialize
                    validationSchema ={Yup.object({
                    organization: Yup.string().required('Campo requerido'),
                    account: Yup.string().required('Campo requerido'),
                    profile: Yup.string().required('Campo requerido'),
                    superset_activo: Yup.boolean(),
                    superset_url: Yup.string().url('Ingresa un url válido debe incluir https://')
                    .when('superset_activo',{
                        is: true,
                        then: Yup.string().required('Debes ingresar una URL')
                    }),
                    })} 
                    onSubmit={async (values, actions) => {
                        const namePerfil = values.profiles.filter(function(item) {
                            return item.id == values.profile.toString();
                          });
                        if(props.newUser=== false){                                
                                  const infoUserAccount ={
                                    uid: props.user.uid,
                                    perfilName: namePerfil[0].nombre,
                                    perfil:values.profile,
                                    nombre: values.accountName,
                                    admin_cuenta: values.admin === true? 'Si': 'No',
                                    base_datos:values.base_datos,
                                    nodo_raiz:values.nodo_raiz?values.nodo_raiz:null,
                                    accountId:values.account,
                                    admin: values.admin === true? 'Si': 'No',
                                    superset_activo: values.superset_activo,
                                    superset_url: values.superset_url.length !=0 ? values.superset_url:null
                                };
                                await props.addUserToAcc(infoUserAccount, values.defaultAccount);   
                                setInputValueOrg('');
                                setInputValueAcc('');
                                setInputValuePrf('');                           
                                                                
                        }else{                      
                            props.addNewAccount({                                   
                                accountId: values.account,
                                perfilName: namePerfil[0].nombre,
                                perfil: values.profile,
                                admin_cuenta: values.admin === true? 'Si': 'No',
                                base_datos:values.base_datos,
                                nodo_raiz:values.nodo_raiz?values.nodo_raiz:null,
                                defaultAccount: values.defaultAccount,
                                superset_activo: values.superset_activo,
                                superset_url: values.superset_url.length !=0 ? values.superset_url:null                                
                            });
                                props.setOpen(false); 
                                setInputValueOrg('');
                                setInputValueAcc('');
                                setInputValuePrf('');          

                        }  
                       
                   
                    }}
                >
                    {(formik, isSubmitting) => (
                    <Form onSubmit={formik.handleSubmit}>
                    
                    <InputLabel>Organización</InputLabel> 
                    <AppAutocomplete
                        name= {'organization'}
                        inputValue={inputValueOrg}
                        data={Object.keys(filteredOrganizations).map((key)=>{
                            const org = filteredOrganizations[key];
                                return{
                                    label: org.nombre,
                                    value: key
                                };
                            })
                        }
                        showNoOptions={false}
                        onInputChange={(e)=>setInputValueOrg(e)}
                        onChange = {async e => {                 
                            const value  = e.value? e.value:'';
                            if(value){
                                const _accounts = await getAccountsOrganization(value);
                                formik.setFieldValue("organization", value);
                                formik.setFieldValue("account", "");                    
                                formik.setFieldValue("accounts", _accounts);
                            }else{
                                formik.setFieldValue("organization", '');
                                formik.setFieldValue("accounts", []);
                            }                                              
                        }}
                    />

                    <AppFormError errorMessage={formik.touched.organization && formik.errors.organization} />

                    <InputLabel>Cuenta</InputLabel> 
                    <AppAutocomplete
                        name= {'account'}
                        inputValue={inputValueAcc}
                        data={Object.keys(formik.values.accounts).map((key)=>{
                                const acc = formik.values.accounts[key];
                                return{
                                    label: acc.nombre,
                                    value: key
                                };
                            })
                        }
                        showNoOptions={false}
                        onInputChange={(e)=>setInputValueAcc(e)}
                        onChange={async (e) => {
                            const value = e.value ? e.value : ''; 
                            if (value) {
                                const _account = await props.getAccountWithId(value);
                                console.log(_account);
                                if (_account) {
                                    formik.setFieldValue("account", value);
                                    formik.setFieldValue("base_datos", _account.base_datos || '');
                                    formik.setFieldValue("nodo_raiz", _account.nodo_raiz || ''); 
                                    formik.setFieldValue("accountName", _account.nombre);
                                    if (props.newUser) {
                                        let perfiles = [];
                                        Object.keys(_account.perfiles).map(key => {
                                            if (_account.perfiles[key].id !== 'inactivo') {
                                                perfiles.push(_account.perfiles[key]);
                                            }
                                        });
                                        formik.setFieldValue("profiles", perfiles);
                                    } else {
                                        formik.setFieldValue("profiles", _account.perfiles);
                                    }
                                } else {
                                    formik.setFieldValue("base_datos", '');
                                    formik.setFieldValue("account", '');
                                    formik.setFieldValue("profiles", []);
                                }
                            } else {
                                formik.setFieldValue("base_datos", '');
                                formik.setFieldValue("account", '');
                                formik.setFieldValue("profiles", []);
                            }
                        }}
                        
                    />

                        
                    <AppFormError errorMessage={formik.touched.account && formik.errors.account} />            
                
                
                    <InputLabel>Perfil</InputLabel> 
                    <AppAutocomplete
                        name= {'profile'}
                        inputValue={inputValuePrf}
                        data={
                            formik.values.profiles && formik.values.profiles.map((perfil) => {
                                return {
                                  label: perfil.nombre,
                                  value: perfil.id
                                };
                              })
                              
                        }
                        showNoOptions={false}
                        onInputChange={(e)=>setInputValuePrf(e)}
                        onChange = {async e => {                  
                            const value  = e.value? e.value:'';
                            if(value){                  
                                formik.setFieldValue("profile", value);
                            }else{
                                formik.setFieldValue("profile", ''); 
                            }                      
                        }}
                        />   

                    <AppFormError errorMessage={formik.touched.profile && formik.errors.profile} />     

                    <InputLabel>URL Superset</InputLabel> 
                    <TextField
                        id="superset_url"
                        variant="standard"
                        name="superset_url"
                        autoComplete="off"
                        type="text"
                        disabled={false}
                        value={formik.values.superset_url}
                        error = {formik.touched.superset_url && !!formik.errors.superset_url}
                        onChange ={async(e) => {
                        formik.setFieldValue('superset_url', e.target.value);                 
                    }}           
                    />
                    <AppFormError errorMessage={formik.touched.superset_url && formik.errors.superset_url} />  

                    <AppSwitch
                        name= {'superset_activo'}
                        label={'Acceso a Superset'}
                        labelLeft={'No'}
                        labelRight={'Si'}
                        className={'switch-button'}
                        checked={formik.values.superset_activo}
                        disabled ={false}
                        value= {formik.values.superset_activo}
                        onChange={(e) => {
                        formik.setFieldValue('superset_activo', e);
                        }}
                    /> 


                    <AppSwitch
                        name= {'admin'}
                        label={'Administrador de usuarios'}
                        labelLeft={'No'}
                        labelRight={'Si'}
                        className={'switch-button'}
                        checked={formik.values.admin}
                        disabled ={false}
                        value= {formik.values.admin}
                        onChange={(e) => {
                        formik.setFieldValue('admin', e);
                        }}
                    />                   
                    {formik.touched.admin && !!formik.errors.admin && <FormHelperText error={true}>Campo requerido</FormHelperText>}
                    
                    <AppSwitch
                    name= {'defaultAccount'}
                    label={'Cuenta por defecto'}
                    labelLeft={'No'}
                    labelRight={'Si'}
                    checked={formik.values.defaultAccount}
                    disabled ={formik.values.defaultAccount}
                    value= {formik.values.defaultAccount}
                    onChange={(e) => {
                      formik.setFieldValue('defaultAccount', e);
                    }}
                    /> 
                    


                    <AppButtonGroup variant="contained" aria-label="outlined primary button group" >
                    <AppButton variant='contained' tipo={BUTTON_TYPES.DEFAULT} onClick={()=>props.handleClose()}>
                        Cancelar
                    </AppButton>       
                    <AppButton variant='contained' tipo = 'modal-amarillos' type='submit'disabled={formik.values.length ===0? true: false}>
                        Aceptar
                    </AppButton>
                    </AppButtonGroup>
                        
                    </Form>

                    )}
                </Formik> 
            </AppPopup>
        </div>
    );
}

export default PopupAssignAccount;
