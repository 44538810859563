import React from 'react';
import AppButton,{BUTTON_TYPES} from '../../Button/AppButton';
import './CardImage.scss';

const CardImage = ({ data = {}, onClickCard = () => {} }) => {
  const { tags = '', category = '', img = '', name = '' } = data;

  const tagsArray = tags ? tags.split(',') : [];
  
  return (
    <div className="card">
      <div className="card-media">
        {category === 'folder' ? (
          <i className="mdi mdi-folder card-icon"></i>
        ) : (
          <img src={img} alt={name} className="card-image" />
        )}
      </div>
      <div className="card-content">
        <div className="tags-container">
          {tagsArray.map((tag, index) => (
            <span key={index} className="chip secondary">
              {tag}
            </span>
          ))}
        </div>
        <h6 className="title">{name}</h6>
      </div>
      <div className="card-actions">
        <AppButton tipo={BUTTON_TYPES.YELLOW_BUTTON} onClick={() => onClickCard(data)}>
          Ver más
        </AppButton>
      </div>
    </div>
  );
};

export default CardImage;
