import React from 'react';

//material
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function Loading(props) {
  const [progress, setProgress] = React.useState(10);

  const LinearProgressWithLabel = (props) => {
    return (
      <Box display='flex' alignItems='center' justifyContent='center' className='loading'>
        <Box width='90%' mr={1}>
          <LinearProgress variant='determinate' {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography variant='body2' color='textSecondary'>{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
       
      </Box>
    );
  };

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 10 : prevProgress + 10
      );
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div>
      <LinearProgressWithLabel value={progress} />
      <Typography variant='body2' align='center'>
      {props.text}
      </Typography >
    </div>
  );
}

export default Loading;
