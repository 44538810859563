export const ENDPOINTS = {
    ORGANIZATION:{
      CREATE: 'v1/organization',
      MASTER_ACCOUNT: (organizacionId, accountId) => `/v1/organization/${organizacionId}/account/${accountId}`,
    },
    DATA: {
      HISTORY: (fb_id) => `v1/data/history?recordId=${fb_id}`,
      REPLICATE_RECORDS : 'v1/data/replicate-records',
    },
    FORM: {
      REPLICATE_FORMS: 'v1/form/replicate-forms',
      FORM_MENU: (accountId) => `/v1/form-menu/${accountId}`,
    },
    ACCOUNT:{
      CREATE: 'v1/account'
    }
  };