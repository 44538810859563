import { useState } from 'react';
import { useAxiosInstance } from '../../../api/axiosInstance';
import { ENDPOINTS } from '../../../api/endpoints';

interface Orgs {
  cuentas: { [key: string]: any };
}

interface FormMenus {
  formularios: { [key: string]: { tipo: string } };
  menu: { [key: string]: { nombre: string } };
}

const useFetchAccountData = (organization: Orgs | null) => {
  const [availableForms, setAvailableForms] = useState<{ value: string; label: string; type: string }[]>([]);
  const axiosInstance = useAxiosInstance();

  const getForms = async (optionAccountOrigin: string): Promise<void> => {
    try {
      if (!organization || !organization.cuentas) {
        return;
      }
      const response = await axiosInstance.get<FormMenus[]>(ENDPOINTS.FORM.FORM_MENU(optionAccountOrigin)); 

      if (Array.isArray(response.data) && response.data.length > 0) {
        const { formularios, menu } = response.data[0];
        if (formularios && menu) {
          const avForms = Object.keys(formularios).map(key => ({
            value: key,
            label: menu[key] ? `${menu[key].nombre} (${key})` : key,
            type: formularios[key].tipo,
          }));
          setAvailableForms(avForms);
        }
      } else {
        console.warn('El response data no es un arreglo o está vacío.');
      }
    } catch (error) {
      console.error('Error fetching forms and menus:', error);
    }
  };

  return { availableForms, getForms };
};

export default useFetchAccountData;
