import React from 'react';
import AppPopup from '../../core/components/Popup/AppPopup';
import AppButton,{BUTTON_TYPES} from '../../core/components/Button/AppButton';
import AppSwitch from '../../core/components/Input/Switch/AppSwitch';
import AppFormError from '../../core/components/FormError/AppFormError';
import AppSelect from '../../core/components/Input/Select/AppSelect';
import AppText from '../../core/components/Input/Text/AppText';
import {ErrorBoundary} from 'react-error-boundary';

//Formik
import { Formik, Form} from 'formik';
import * as Yup from "yup";
import './index.scss';

//Componentes core
import AppButtonGroup from '../../core/components/ButtonGroup/AppButtonGroup';

/**
 * Represents a popup for displaying and editing selected user account information.
 * @param {Object} props - The component's props.
 * @param {Function} props.handlePopupAccountSelected - A function to close the popup.
 * @param {boolean} props.open - A boolean indicating whether the popup is open.
 * @param {Object} props.accountSelected - An object containing the selected user account data.
 * @param {boolean} props.disabled - A boolean indicating whether the form fields should be disabled.
 * @param {Object} props.user - An object containing user data.
 * @param {Function} props.update - A function to update the user account information.
 * @param {boolean} props.newUser - A boolean indicating if it's a new user.
 * @param {Function} props.updateAccount - A function to update the user account information.
 * @param {Function} props.changeDefaultAccount - A function to change the default account.
 */

function PopupAccountSelected(props) {

    const formatProfiles = (perfiles) =>{
      let perf = [];
      perfiles.map((perfil)=>{
          perf.push({
              value: perfil.id,
              label: perfil.nombre
          });
      });
      return perf;
  };
   
    //Retorna nos botones del final del Popup dependiendo del valor de disabled
    const EndButtons = () =>{  
      if(props.disabled){
        return(
          <AppButtonGroup variant="contained" aria-label="outlined primary button group" >
              <AppButton variant='contained' className="group_button" tipo={BUTTON_TYPES.DEFAULT} onClick={()=>props.handleClose()}>
                  Salir
              </AppButton>               
          </AppButtonGroup>
        );
      }else{
        return(
        <AppButtonGroup variant="contained" aria-label="outlined primary button group" >
          <AppButton variant='contained' className="group_button" tipo={BUTTON_TYPES.DEFAULT} onClick={()=>props.handleClose()}>
            Cancelar
          </AppButton>       
          <AppButton variant='contained' className="group_button" tipo = 'modal-amarillos'  type='submit'>
            Aceptar
          </AppButton>
        </AppButtonGroup>
        );
      }
    };
      //Permite imprimir el error
    const errorHandler = (error, errorInfo)=>{
      throw new Error ('Loging', error, errorInfo);
    };  
    
    return (
      
        <div className='modal'>
          {Object.keys(props.accountSelected).length === 0 ? <h1> </h1>
        :
        <ErrorBoundary
        onError={errorHandler}
        > 
        <AppPopup title='Cuenta seleccionada' onClose={props.handlePopupAccountSelected} open={props.open} className='header'>
          <Formik
            initialValues={{
              account:props.accountSelected.nombre?props.accountSelected.nombre:'',
              name: props.user.nombre,
              email: props.user.correo,
              profile: props.accountSelected.IdPerfilUsuario,
              perfiles: formatProfiles(props.accountSelected.perfiles),
              admin_cuenta: props.accountSelected.admin_cuenta=== 'Si' ? true : false,
              defaultAccount: props.accountSelected.id === props.user.defaultAccount? true: false,
              superset_activo:props.accountSelected.superset_activo?props.accountSelected.superset_activo:false,
              superset_url: props.accountSelected.superset_url?props.accountSelected.superset_url:''
            }}
            enableReinitialize

              validationSchema ={Yup.object({
                name: Yup.string()
                  .max(40, 'Debe tener 40 caracteres o menos')
                  .required('Campo requerido'),
                email: Yup.string().email('Correo electrónico inválido').required('Campo requerido'),
                profile: Yup.string('Nombre de perfil inválido'),
                account: Yup.string('La versión no es válida'),
                admin_cuenta:Yup.string('La versión no es válida'),
                superset_activo: Yup.boolean(),
                superset_url: Yup.string().url('Ingresa un url válido debe incluir https://')
                .when('superset_activo',{
                    is: true,
                    then: Yup.string().required('Debes ingresar una URL')
                }),
                })} 
            onSubmit={async (values, actions) => {
              const namePerfil = props.accountSelected.perfiles.filter(function(item) {
                return item.id == values.profile.toString();
              });
              if(props.newUser){
                const inforAcct = {
                  accountId: props.accountSelected.id,
                  perfilName: namePerfil[0].nombre,
                  perfil: values.profile,
                  admin_cuenta: values.admin_cuenta?"Si":"No",
                  nodo_raiz:props.accountSelected.nodo_raiz?props.accountSelected.nodo_raiz:null,
                  base_datos: props.accountSelected.base_datos,
                  defaultAccount: values.defaultAccount,
                  superset_activo: values.superset_activo,
                  superset_url: values.superset_url.length !=0 ? values.superset_url:null
                };
                props.update(inforAcct);
                  
              }else{
                let adminString = values.admin_cuenta === true? "Si": "No";            
                const inforAcct = {
                  uid: props.user.uid,
                  nodo_raiz: props.accountSelected.nodo_raiz?props.accountSelected.nodo_raiz:null,
                  base_datos: props.accountSelected.base_datos,
                  nombre: props.accountSelected.nombre,
                  perfil: values.profile,
                  perfilName: namePerfil[0].nombre,
                  admin_cuenta: adminString.toString(),
                  accountId: props.accountSelected.id,
                  superset_activo: values.superset_activo,
                  superset_url: values.superset_url.length !=0 ? values.superset_url:null
                };
                await props.updateAccount(inforAcct);
                
                
                if(values.defaultAccount){
                  const defaultInfo ={
                    uid: props.user.uid,
                    perfil: values.profile,
                    perfilName: namePerfil[0].nombre,
                    nodo_raiz: props.accountSelected.nodo_raiz?props.accountSelected.nodo_raiz:null ,
                    base_datos: props.accountSelected.base_datos,
                    accountId:inforAcct.accountId,
                    superset_activo: values.superset_activo,
                    superset_url: values.superset_url.length !=0 ? values.superset_url:null
                  };
                  await props.changeDefaultAccount(defaultInfo);
                }
              }           

            }
          }
          >
            {(formik ) => (
          <Form onSubmit={formik.handleSubmit}>
              <AppText
                id="account"
                name="account"
                type='text'
                label="Cuenta seleccionada"                
                disabled={true}
                value={formik.values.account}
                onChange={formik.handleChange}
                error = {formik.touched.account && !!formik.errors.account}
                helperText={formik.touched.account && formik.errors.account}
              />
              <AppFormError errorMessage={formik.touched.name && formik.errors.name} />
              <AppText
                id="name"
                name="name"
                type='text'
                label="Nombre de usuario"
                disabled={true}
                value={formik.values.name}
                onChange={formik.handleChange}
                error = {formik.touched.name && !!formik.errors.name}        
               />
              <AppFormError errorMessage={formik.touched.name && formik.errors.name} /> 

              <AppText
                className='email'
                name = 'email'
                type='email'
                label="Correo electrónico"
                disabled={true}
                value={formik.values.email}
                onChange={formik.handleChange}
                error = {formik.touched.email && !!formik.errors.email}
               />
              <AppFormError errorMessage={formik.touched.email && formik.errors.email} />           

              <AppSelect
              id = 'profile'
              name = 'profile'
              label ='Perfil'
              options = {formik.values.perfiles}
              value = {formik.values.profile}
              errors = {formik.touched.profile && !!formik.errors.profile}
              errorMessage = {formik.touched.profile && formik.errors.profile}
              disabled={props.disabled}
              onChange={(e) => {
                formik.setFieldValue('profile', e.target.value);                
            }}
              />  


            <AppText
            id = 'superset_url'
            name = 'superset_url'
            label = 'URL Superset'
            value ={formik.values.superset_url}
            disabled={props.disabled}
            errors={formik.touched.superset_url && !!formik.errors.superset_url}
            errorMessage = {formik.touched.superset_url && formik.errors.superset_url}
            onChange={formik.handleChange}
            />  
            

            <AppSwitch
            name= {'superset_activo'}
            label={'Acceso a Superset'}
            labelLeft={'No'}
            labelRight={'Si'}
            className={'switch-button'}
            checked={formik.values.superset_activo}
            disabled={props.disabled}
            value= {formik.values.superset_activo}
            onChange={(e) => {
            formik.setFieldValue('superset_activo', e);
            }}
            /> 


              <AppSwitch
              name= {'admin_cuenta'}
              label={'Administrador de usuarios'}
              labelLeft={'No'}
              labelRight={'Si'}
              checked={formik.values.admin_cuenta}
              value= {formik.values.admin_cuenta}
              disabled={props.disabled}
              onChange={(e) => {
                formik.setFieldValue('admin_cuenta', e);
              }}
              /> 
        
              <AppSwitch
              name= {'defaultAccount'}
              label={'Cuenta por defecto'}
              labelLeft={'No'}
              labelRight={'Si'}
              checked={formik.values.defaultAccount}
              disabled ={formik.values.defaultAccount|| props.disabled}
              value= {formik.values.defaultAccount}
              onChange={(e) => {
                formik.setFieldValue('defaultAccount', e);
              }}
              />         

            <div className='button_confirm_container'>
              <div className='buttons_end'>
                  {EndButtons()}
              </div>
            </div> 
                   
            </Form>
            )}
        </Formik> 

        </AppPopup>
        </ErrorBoundary> 
      }
            
        </div>
    );
}

export default PopupAccountSelected;